import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import {
  addMonths,
  eachMonthOfInterval,
  isWithinInterval,
  startOfMonth,
} from 'date-fns';
import { formatToBRTz } from './utils';

const MonthButton = ({ label, checked = false, onClick }) => (
  <span
    role="button"
    aria-checked={checked}
    className="mb-4 mr-4 inline-block rounded-2xl border border-solid border-neutral-300 px-4 py-1.5 text-body font-semibold hover:bg-secondary-100 hover:text-secondary aria-checked:border-secondary aria-checked:bg-secondary-100 aria-checked:text-secondary"
    onClick={onClick}
  >
    {label}
    {checked && <AiOutlineClose className="ml-2.5" />}
  </span>
);

const CalendarStatus = {
  Idle: 0,
  Selecting: 1,
};

export const YearCalendar = ({ interval, onChange }) => {
  const [status, setStatus] = useState(CalendarStatus.Idle);
  const months = eachMonthOfInterval({
    start: startOfMonth(new Date()),
    end: addMonths(startOfMonth(new Date()), 8),
  });

  function isChecked(day) {
    return isWithinInterval(day, {
      start: interval.start,
      end: interval.end || interval.start,
    });
  }

  function handleIntervalChange(date) {
    if (date < interval.start) return;

    if (status === CalendarStatus.Idle) {
      setStatus(CalendarStatus.Selecting);
      onChange({ start: date, end: date });
    } else {
      setStatus(CalendarStatus.Idle);
      onChange({ start: interval.start, end: date });
    }
  }

  return (
    <>
      <MonthButton label="Todos os meses" />
      {months.map((month) => (
        <MonthButton
          key={month.toISOString()}
          label={formatToBRTz(month, 'MMMM/yyyy')}
          checked={isChecked(month)}
          onClick={() => handleIntervalChange(month)}
        />
      ))}
    </>
  );
};

export const YearCalendarContainer = ({ children }) => (
  <div className="px-5 pt-4">
    <p className="text-small font-semibold">
      Selecione o mês (ou meses) que deseja buscar.
    </p>
    {children}
  </div>
);
