import { forwardRef, useState } from 'react';
import { addMonths, subMonths } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { CalendarFooter } from './CalendarFooter';
import { MonthCalendar, MonthCalendarContainer } from './MonthCalendar';
import { MonthLabel, MonthLabelContainer } from './MonthLabel';
import { Tab, TabContainer } from './Tab';
import { toBRTimeZone } from './utils';
import { YearCalendar, YearCalendarContainer } from './YearCalendar';

const CalendarStatus = {
  Empty: 0,
  Selecting: 1,
  Selected: 2,
};
const CalendarType = {
  Daily: 0,
  Monthly: 1,
};
const NavDirection = {
  Prev: 0,
  Next: 1,
};

export const CalendarSelector = forwardRef(function CalendarSelector(
  { className, interval, changeOnly, onReset, onApply },
  ref,
) {
  const [status, setStatus] = useState(CalendarStatus.Empty);
  const [activeCalendar, setActiveCalendar] = useState(CalendarType.Daily);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedMonths, setSelectedMonths] = useState({});
  const [monthCalendars, setMonthCalendars] = useState(() => {
    const now = toBRTimeZone(new Date());
    const nextMonth = addMonths(now, 1);
    return [{ date: now }, { date: nextMonth }];
  });

  function handleTabChange(index) {
    setActiveCalendar(index);
    handleReset();
  }

  function handleMonthNavigation(direction) {
    if (direction === NavDirection.Prev)
      setMonthCalendars((prev) =>
        prev.map((calendar) => ({
          date: subMonths(calendar.date, prev.length),
        })),
      );
    else
      setMonthCalendars((prev) =>
        prev.map((calendar) => ({
          date: addMonths(calendar.date, prev.length),
        })),
      );
  }

  function handleSelectedDateChange(date) {
    const isStartAhead = interval.start > date;
    if (isStartAhead && changeOnly === 'end') return;

    setStatus(CalendarStatus.Selecting);
    setSelectedDate(date);
  }

  function handleSelectedMonthsChange({ start, end }) {
    setStatus(CalendarStatus.Selecting);
    setSelectedMonths({ start, end });
  }

  function makePreviewInterval() {
    const intervalDate = changeOnly === 'end' ? interval.end : interval.start;
    const previewDate =
      status === CalendarStatus.Selecting ? selectedDate : intervalDate;

    if (changeOnly === 'start') return { ...interval, start: previewDate };
    else if (changeOnly === 'end') return { ...interval, end: previewDate };
    else return { start: selectedDate, end: selectedDate };
  }

  function handleReset() {
    setStatus(CalendarStatus.Empty);
    setSelectedMonths({});
    setSelectedDate();
    onReset();
  }

  function handleApply() {
    setStatus(CalendarStatus.Selected);

    if (activeCalendar === CalendarType.Daily) onApply(selectedDate);
    else onApply(selectedMonths.start, selectedMonths.end);
  }

  return (
    <div
      className={twMerge(
        `w-full overflow-hidden rounded-md border
        border-solid border-neutral-400 bg-white p-0 shadow`,
        className,
      )}
      ref={ref}
    >
      <TabContainer>
        <Tab
          active={activeCalendar === CalendarType.Daily}
          onClick={() => handleTabChange(CalendarType.Daily)}
        >
          Data exata
        </Tab>
        {/* <Tab
          active={activeCalendar === CalendarType.Monthly}
          onClick={() => handleTabChange(CalendarType.Monthly)}
        >
          Por mês
        </Tab> */}
      </TabContainer>

      {activeCalendar === CalendarType.Daily && (
        <>
          <MonthLabelContainer
            onPrevClick={() => handleMonthNavigation(NavDirection.Prev)}
            onNextClick={() => handleMonthNavigation(NavDirection.Next)}
          >
            {monthCalendars.map((calendar, idx) => (
              <MonthLabel key={idx} date={calendar.date} />
            ))}
          </MonthLabelContainer>
          <MonthCalendarContainer>
            {monthCalendars.map((calendar, idx) => (
              <MonthCalendar
                key={idx}
                date={calendar.date}
                interval={makePreviewInterval()}
                onChange={handleSelectedDateChange}
              />
            ))}
          </MonthCalendarContainer>
        </>
      )}
      {activeCalendar === CalendarType.Monthly && (
        <YearCalendarContainer>
          <YearCalendar
            interval={selectedMonths}
            onChange={handleSelectedMonthsChange}
          />
        </YearCalendarContainer>
      )}

      <CalendarFooter
        interval={makePreviewInterval()}
        disabled={status === CalendarStatus.Empty}
        onReset={handleReset}
        onApply={handleApply}
      />
    </div>
  );
});
