import { forwardRef, useId } from 'react';

// eslint-disable-next-line react/display-name
export const SelectInput = forwardRef(
  ({ className, options, ...props }, ref) => {
    const id = useId();
    const hasLabelData = !props.label ? 'hidden' : '';

    return (
      <div className="flex w-full flex-col items-start justify-between gap-2">
        <label
          htmlFor={id}
          className={`m-0 h-5 text-sm font-medium text-neutral-800 ${hasLabelData}`}
        >
          {props.label}
        </label>
        <select
          id={id}
          ref={ref}
          className={`h-12 w-full items-center justify-between rounded-md !border !border-solid !border-neutral-600 bg-transparent !px-4 !py-[14px] text-sm font-normal placeholder:italic ${className}`}
          {...props}
        >
          <option value="Selecione"></option>
          {options.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);
