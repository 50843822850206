import { useRef, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { MdOutlinePlace } from 'react-icons/md';
import { TbPlane } from 'react-icons/tb';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { InputRadio, InputText } from '@components/input';
import {
  InputTextDropdown,
  InputTextDropdownItem,
  InputDatePicker,
} from '@components/input-custom';
import { AirportDto } from '@data'; // eslint-disable-line no-unused-vars
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from '../pages-config';

const TripType = {
  RoundTrip: 'round-trip',
  OneWay: 'one-way',
};

export default function AereoReservas() {
  const { session } = useAuthentication();
  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  const departureDateInputRef = useRef(null);

  const [tripType, setTripType] = useState(TripType.RoundTrip);
  const [formFields, setFormFields] = useState({
    origin: '',
    destination: '',
    departureDate: '',
    returnDate: '',
    passengers: 1,
  });
  /**
   * @type {ReturnType<typeof useState<AirportDto[]>>}
   */
  const [airports, setAirports] = useState([]);

  async function fetchAirports(e) {
    setAirports([]);
    const response = await api.get('/airports', {
      params: { search: e.target.value, limit: 5 },
    });
    setAirports(response.data);
  }

  function handleTripTypeChange(e) {
    setTripType(e.target.value);

    if (e.target.value === TripType.OneWay)
      setFormFields({
        ...formFields,
        returnDate: '',
      });
  }

  function handleInputChange(e) {
    setFormFields({
      ...formFields,
      [e.target.name]: e.target.value,
    });
  }

  function handleAirportClick(type, label) {
    setFormFields({
      ...formFields,
      [type]: label,
    });
  }

  function handleDepartureDateChange(date) {
    const newReturnDate =
      date > formFields.returnDate ? '' : formFields.returnDate;
    setFormFields({
      ...formFields,
      departureDate: date,
      returnDate: newReturnDate,
    });
  }

  function handleReturnDateChange(date) {
    setFormFields({
      ...formFields,
      returnDate: date,
    });
  }

  function handleReturnDateClick(showCalendar) {
    if (formFields.departureDate) return showCalendar();
    departureDateInputRef.current.focus();
    departureDateInputRef.current.click();
  }

  /**
   * @todo
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (!validateFormFields()) return;

    console.log('formFields:', formFields);
  }

  /**
   * @todo
   */
  function validateFormFields() {
    return true;
  }

  function renderAirportsDropdown(type) {
    return airports.map((airport) => {
      const search = formFields[type];
      const label = `${airport.name}, ${airport.city} (${airport.iata})`;
      const text = highlightSearchText(label, search);

      return (
        <InputTextDropdownItem
          key={airport.iata}
          onClick={() => handleAirportClick(type, label)}
        >
          {text}
        </InputTextDropdownItem>
      );
    });
  }

  function highlightSearchText(text, search) {
    const snippet = text.match(new RegExp(search, 'i'))?.at(0);
    if (!snippet) return text;

    const index = text.toLowerCase().indexOf(snippet.toLowerCase());
    return (
      <>
        {text.slice(0, index)}
        <span className="text-secondary">{snippet}</span>
        {text.slice(index + snippet.length)}
      </>
    );
  }

  return (
    <>
      <BreadCrumbs
        paths={[
          { label: 'Aéreo', link: PagePath.Aereo },
          { label: 'Reservas', link: PagePath.AereoReservas },
        ]}
      />
      <Container>
        <h2 className="m-0 text-heading-2 text-primary">{pageTitle}</h2>
      </Container>
      <Container className="mt-4">
        <div className="sm:flex">
          <h2 className="mr-6 text-heading-2 text-primary">Aéreo</h2>
          <div className="flex space-x-6">
            <InputRadio
              label="Ida e volta"
              name="trip-type"
              value={TripType.RoundTrip}
              checked={tripType === TripType.RoundTrip}
              onChange={handleTripTypeChange}
            />
            <InputRadio
              label="Somente 1 trecho"
              name="trip-type"
              value={TripType.OneWay}
              checked={tripType === TripType.OneWay}
              onChange={handleTripTypeChange}
            />
          </div>
        </div>
        <div className="mt-6">
          <form onSubmit={handleSubmit}>
            <div className="flex space-x-2.5">
              <InputTextDropdown
                name="origin"
                label="Origem"
                placeholder="Insira a origem"
                icon={MdOutlinePlace}
                dropdownTitle="Aeroportos"
                dropdownIcon={TbPlane}
                value={formFields.origin}
                required
                onChange={handleInputChange}
                onDropdownAppears={fetchAirports}
              >
                {renderAirportsDropdown('origin')}
              </InputTextDropdown>
              <InputTextDropdown
                name="destination"
                label="Destino"
                placeholder="Insira o destino"
                icon={MdOutlinePlace}
                dropdownTitle="Aeroportos"
                dropdownIcon={TbPlane}
                value={formFields.destination}
                required
                onChange={handleInputChange}
                onDropdownAppears={fetchAirports}
              >
                {renderAirportsDropdown('destination')}
              </InputTextDropdown>
            </div>
            <div className="mt-4 flex items-end space-x-2.5">
              <InputDatePicker
                name="departureDate"
                label="Data da ida"
                placeholder="Insira a data da ida"
                value={formFields.departureDate}
                required
                intervalStart={formFields.departureDate}
                changeOnly="start"
                onChange={handleDepartureDateChange}
                ref={departureDateInputRef}
              />
              <InputDatePicker
                name="returnDate"
                label="Data da volta"
                placeholder="Insira a data da volta"
                value={formFields.returnDate}
                required={tripType === TripType.RoundTrip}
                disabled={tripType === TripType.OneWay}
                intervalStart={formFields.departureDate}
                intervalEnd={formFields.returnDate}
                changeOnly="end"
                onClick={handleReturnDateClick}
                onChange={handleReturnDateChange}
              />
              <InputText
                type="number"
                name="passengers"
                label="Passageiros"
                placeholder="Insira o n. de passageiros"
                icon={BiUser}
                value={formFields.passengers}
                required
                onChange={handleInputChange}
              />
              <Button type="submit" className="basis-1/2" label="Buscar" />
            </div>
          </form>
        </div>
      </Container>
    </>
  );
}
