import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Alert } from '@components/alert';
import { Button } from '@components/button';
import { useBookings } from '@stores/bookings';
import { PagePath } from '../../pages-config';
import ReservationTravelersList from './reservation-passenger-list';

export const ReservationPassenger = () => {
  const { travelers } = useBookings();
  const { state } = useLocation();
  const navigate = useNavigate();
  const confirmTravelers = () => {
    navigate(PagePath.AereoReservasPagamento, { state });
  };

  return (
    <>
      <div className="sticky left-0 right-0 top-4 flex max-h-[calc(100vh-90px)] w-full  flex-col ">
        <div className="   w-full flex-col flex-wrap self-start overflow-y-auto  rounded-lg border border-solid !border-neutral-300 bg-white p-4  ">
          <div className="  mb-4 border-0 border-b border-solid border-[#EBEBEB] pb-3 text-heading-3 text-primary">
            Passageiros a confirmar{' '}
            {travelers?.length > 0 ? `(${travelers.length})` : ''}
          </div>
          {travelers.length === 0 && (
            <div className=" text-body text-neutral-800">
              Nenhum passageiro adicionado.
            </div>
          )}
          {travelers.length > 0 && <ReservationTravelersList />}
        </div>
        {travelers.length > 0 && (
          <div className="mt-4 w-full">
            <Alert fullWidth type="warning">
              A reserva fica disponível para pagamento até às 23:59 de hoje,
              {dayjs().format('DD/MM/YYYY')}.
            </Alert>
          </div>
        )}
        {travelers.length > 0 && (
          <div className=" mt-4 w-full">
            <Button
              className="w-full"
              variant="default"
              label="Continuar"
              onClick={confirmTravelers}
            />
          </div>
        )}
      </div>
    </>
  );
};
