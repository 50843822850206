import { useState } from 'react';
import { BsSuitcase2Fill } from 'react-icons/bs';
import { BsBagFill } from 'react-icons/bs';
import { BsInfoCircle } from 'react-icons/bs';
import { FaSuitcaseRolling } from 'react-icons/fa';
import { PiAirplaneTakeoffLight, PiAirplaneLandingLight } from 'react-icons/pi';
import PropTypes from 'prop-types';
import { Button } from '@components/button';
import { ModalInfoBooking } from '@components/modal/ModalInfoBooking';
import { Tooltip } from '@components/tooltip';
import {
  formatDateAereoReservas,
  formatTime,
  calculateFlightDuration,
  hasBaggage,
  formatCurrency,
  formatCityAirportState,
  getCityAirport,
  formatDayMonth,
  formatScaleOnlyStop,
  formatBaggageByQuantityAndWeight,
} from '@system/utils';

function tooltipScaleComponent(props) {
  if (props.totalConnection == 0 && props.totalScale == 0)
    return <span className="text-xs">Voo Direto, sem paradas</span>;
  return (
    <div className="flex flex-col gap-2 text-left">
      {props.originScale !== null && (
        <div className="flex flex-col">
          <span className="text-secondary">
            {getCityAirport(props.airports, props.originScale)} (
            {props.originScale}) (Escala)
          </span>
        </div>
      )}
      {props.connectionAirpoirt !== null && (
        <div className="flex flex-col">
          <span className="text-secondary">
            {getCityAirport(props.airports, props.connectionAirpoirt)} (
            {props.connectionAirpoirt}) (Conexão)
          </span>
        </div>
      )}
    </div>
  );
}

function tooltipNextDayComponent(props) {
  return (
    <div className="flex flex-col text-left">
      <span className="text-secondary">Chega no outro dia</span>
      <span className="text-neutral-800">
        {' '}
        Este voo chega no outro dia, {formatDayMonth(props.destinationDate)}, às{' '}
        {formatTime(props.destinationTime)?.replace('h', '')}{' '}
      </span>
    </div>
  );
}

function tooltipBaggageComponent(hasBaggageColor, baggage) {
  const baggageValue = hasBaggage(baggage)
    ? formatBaggageByQuantityAndWeight(baggage)
    : '';

  return (
    <div className="flex flex-col items-baseline gap-3 py-2 text-left text-xs">
      <div className="flex items-center gap-3">
        <BsBagFill size={12} color="#E41047" />
        <div className="flex flex-col">
          <span className={'text-secondary'}>Inclui uma mochila ou bolsa</span>
          <span>Deve caber embaixo do assento dianteiro.</span>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <FaSuitcaseRolling size={14} color="#E41047" />
        <div className="flex flex-col">
          <span className={'text-secondary'}>Inclui bagagem de mão</span>
          <span>Deve caber no compartimento superior do avião.</span>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <BsSuitcase2Fill size={16} color={hasBaggageColor} />
        <div className="flex flex-col">
          <span className={hasBaggage(baggage) ? 'text-secondary' : ''}>
            {hasBaggage(baggage)
              ? `Inclui ${baggageValue.quantity} para despachar`
              : 'Não inclui bagagem para despachar'}
          </span>
          <span>
            {hasBaggage(baggage)
              ? `A bagagem deve ter no máximo ${baggageValue.weight}.`
              : 'Para adquirir bagagem, entrar em contato com a VP.'}
          </span>
        </div>
      </div>
    </div>
  );
}

function formatCompanyName(company) {
  return company.split(' ')[0].toUpperCase() ?? '-';
}

const FlightCard = ({ isDeparture, props, className }) => {
  const [openModal, setOpenModal] = useState(false);
  const hasBaggageColor = hasBaggage(props.baggage) ? '#E41047' : '#BFC4CD';
  const scale = formatScaleOnlyStop(props.totalScale, props.totalConnection);

  return (
    <div
      className={`${className} grid grid-cols-1 grid-rows-11 lg:grid-cols-11 lg:grid-rows-5`}
    >
      <div
        className={`col-start-1 row-start-1 flex items-center gap-3 bg-neutral-100 p-3 lg:col-span-2 lg:col-start-1 lg:row-span-2 lg:row-start-1`}
      >
        {isDeparture && <PiAirplaneTakeoffLight size={24} color="#162260" />}
        {!isDeparture && <PiAirplaneLandingLight size={24} color="#162260" />}
        <div className="row text-primary">
          <span className="text-sm font-semibold">
            {' '}
            {isDeparture ? 'IDA' : 'VOLTA'}{' '}
          </span>
          <span className="text-xs">
            {formatDateAereoReservas(props.originDate)}
          </span>
        </div>
      </div>

      <div
        className={`col-start-1 row-start-3 flex items-center pl-5 lg:col-span-2 lg:col-start-3 lg:row-span-2 lg:row-start-1`}
      >
        <div className="flex flex-col gap-0.5">
          <span className="p-0 text-sm font-semibold text-neutral-800">
            {props.origin}
          </span>
          <span className="p-0 text-xs">
            {formatCityAirportState(props.airports, props.origin)}
          </span>
        </div>
      </div>

      <div className={`lg:col-start-5 lg:row-span-2 lg:row-start-1`}></div>

      <div
        className={`col-start-1 row-start-6 flex items-center pl-5 lg:col-span-2 lg:col-start-5 lg:row-span-2 lg:row-start-1`}
      >
        <div className="flex flex-col gap-0.5">
          <span className="p-0 text-sm font-semibold text-neutral-800">
            {props.destination}
          </span>
          <span className="p-0 text-xs">
            {formatCityAirportState(props.airports, props.destination)}
          </span>
        </div>
      </div>

      <div
        className={`col-start-1 row-start-8 flex items-center pl-5 lg:col-span-2 lg:col-start-7 lg:row-span-2 lg:row-start-1`}
      >
        <span className="p-0 text-sm text-neutral-800">Duração</span>
      </div>

      <div
        className={`col-start-1 row-start-10 flex items-center pl-5 lg:col-span-2 lg:col-start-9 lg:row-span-2 lg:row-start-1`}
      >
        <span className="text-sm text-neutral-800">Bagagem</span>
      </div>

      <div
        className={`col-start-1 row-start-2 self-center border-0 border-b border-solid border-[#EDEDED] p-5 lg:col-span-2 lg:col-start-1 lg:row-span-3 lg:row-start-3 lg:border-0 lg:p-3`}
      >
        <div className="flex flex-row gap-2">
          <img className="w-8" src={props.companyLogo} alt="" />
          <span className="text-xs text-neutral-800">
            {formatCompanyName(props.company)}
          </span>
        </div>
        {props.operator && (
          <p className="m-0 pt-2 text-xs text-neutral-800">
            Operado por {props.operator}
          </p>
        )}
      </div>

      <div
        className={`col-start-1 row-start-4 flex flex-row items-center border-0 border-b border-solid border-[#EDEDED] pl-5 lg:col-span-1 lg:col-start-3 lg:row-span-3 lg:row-start-3 lg:border-0`}
      >
        <span className="text-base font-semibold text-neutral-800">
          {formatTime(props.originTime)?.replace('h', '')}
        </span>
      </div>
      <div
        className={`col-start-3 row-start-5 flex justify-center self-center border-0 border-b border-solid border-[#EDEDED] p-5 lg:col-start-4 lg:row-span-3 lg:row-start-3 lg:border-0 lg:p-0 lg:text-center`}
      >
        <Tooltip
          id={`departureScaleTooltip-${props.index}`}
          baseComponent={
            <span className="cursor-pointer self-center text-nowrap text-end text-sm font-semibold text-secondary">
              {scale}
            </span>
          }
          tooltipComponent={tooltipScaleComponent(props)}
        />
      </div>
      <div
        className={`m1-10 col-start-1 row-start-2 flex justify-center self-center border-0 border-b border-solid border-[#EDEDED] p-5 lg:col-start-4 lg:row-span-3 lg:row-start-3 lg:border-0 lg:p-0 lg:text-center`}
      />
      <div
        className={`col-start-1 row-start-7 flex items-center gap-1.5 border-0 border-b border-solid border-[#EDEDED] pl-5 lg:col-span-2 lg:col-start-5 lg:row-span-3 lg:row-start-3 lg:border-0`}
      >
        <span className="text-nowrap p-0 text-end text-base font-semibold text-neutral-800">
          {formatTime(props.destinationTime)?.replace('h', '')}
        </span>
        {props?.nextDay == '1' && (
          <Tooltip
            id={`nextDayDepartureTooltip-${props.index}`}
            baseComponent={
              <sup className="!z-0 cursor-pointer text-xs font-semibold text-secondary ">
                +{props?.nextDay}
              </sup>
            }
            tooltipComponent={tooltipNextDayComponent(props)}
          />
        )}
      </div>

      <div
        className={`col-start-1 row-start-9 flex items-center border-0 border-b border-solid border-[#EDEDED] pl-5 lg:col-span-2 lg:col-start-7 lg:row-span-3 lg:row-start-3 lg:border-0`}
      >
        <span className="p-0 text-base font-semibold text-neutral-800">
          {calculateFlightDuration(
            props.originTime,
            props.originDate,
            props.destinationTime,
            props.destinationDate,
          )}
        </span>
      </div>

      <div
        className={`col-start-1 row-start-11 flex flex-row items-center gap-12 border-0 border-b border-solid border-[#EDEDED] pl-5 lg:col-span-2 lg:col-start-9 lg:row-span-3 lg:row-start-3 lg:border-0 2xl:gap-20`}
      >
        <Tooltip
          id={`departureBaggageTooltip-${props.index}`}
          baseComponent={
            <div className="flex cursor-pointer items-baseline gap-1 ">
              <BsBagFill size={12} color="#E41047" />
              <FaSuitcaseRolling size={14} color="#E41047" />
              <BsSuitcase2Fill size={20} color={hasBaggageColor} />
            </div>
          }
          tooltipComponent={tooltipBaggageComponent(
            hasBaggageColor,
            props.baggage,
          )}
        />

        <BsInfoCircle
          size={20}
          color="#E41047"
          className="cursor-pointer"
          onClick={() => setOpenModal(true)}
        />
      </div>
      {openModal && (
        <ModalInfoBooking
          open={openModal}
          props={{ isDeparture, ...props }}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

function BookingRouteCard(props) {
  const className = props?.className;

  const borderBottomReturn = props.return.origin
    ? 'border-0 border-b border-solid border-[#EDEDED]'
    : 'border-0 border-b border-solid border-[#EDEDED] lg:border-0';

  return (
    <>
      <div
        className={`grid w-full auto-cols-max grid-cols-1 rounded-md border border-solid border-[#EDEDED] bg-white lg:grid-cols-12 ${className}`}
      >
        <div className="col-span-10 lg:border-0 lg:border-r lg:border-solid lg:border-[#EDEDED]">
          <div
            className={`grid ${props.return.origin ? 'lg:grid-rows-1' : 'lg:grid-rows-1'}`}
          >
            <FlightCard
              key="departure-card"
              isDeparture={true}
              props={{
                airports: props.airports,
                baggage: props.baggage,
                ...props.departure,
              }}
              className={borderBottomReturn}
            />
            {props.return.origin && (
              <FlightCard
                key="return-card"
                isDeparture={false}
                props={{
                  airports: props.airports,
                  baggage: props.baggage,
                  ...props.return,
                }}
              />
            )}
          </div>
        </div>

        <div className="col-span-2 grid grid-cols-3 content-baseline justify-end p-5 align-top lg:text-end">
          <p className="col-span-3 m-0 lg:col-start-1">Preço por adulto</p>
          <p className="col-span-3 col-start-1 text-base text-price">
            R$
            <span className="text-2xl font-semibold">
              {formatCurrency(props.price).split('R$')[1]}
            </span>{' '}
          </p>
          <Button
            className="col-span-3 col-start-1 mt-0 !w-full !justify-center"
            label="Reservar"
            onClick={props.onClick}
          />
        </div>
      </div>
    </>
  );
}

BookingRouteCard.propTypes = {
  key: PropTypes.string,
  baggage: PropTypes.string,
  price: PropTypes.string,
  airports: PropTypes.array,

  // Departure
  departure: PropTypes.object,

  // Return
  return: PropTypes.object,
};

export default BookingRouteCard;
