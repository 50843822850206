import { GoCheckCircle } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/button';
import { useBookings } from '@stores/bookings';
import { Modal } from '.';
import { PagePath } from '../../pages-config';

export function ModalReservationConfirm() {
  const navigate = useNavigate();
  const { modal, booking, methods } = useBookings();
  if (!booking) return;
  const onClose = () => {
    methods.updateModal('bookingConfirmed', false);
    methods.reset();
    navigate(PagePath.AereoReservas, { replace: true });
  };
  return (
    <Modal className="w-max" open={modal?.bookingConfirmed}>
      <div className="m-0 flex flex-col items-center gap-3 px-[150px] py-[30px]">
        <GoCheckCircle size={40} className="text-price" />
        <span className="m-0 text-center text-heading-1 text-price">
          Reserva <b className="font-bold">{booking.code}</b> confirmada!
        </span>
        <p className="m-0 mb-[30px] mt-[15px] w-2/3 text-center">
          A agência vai receber um e-mail com todas as informações da reserva.
        </p>
        <Button onClick={onClose} variant="ghost" label="Fechar" />
      </div>
    </Modal>
  );
}
