import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

const button = tv({
  slots: {
    base: 'm-0 w-max bg-secondary hover:bg-secondary-900 disabled:bg-neutral-400 disabled:hover:bg-neutral-400',
    textColor: 'text-white disabled:text-white',
  },
  variants: {
    default: {
      base: 'bg-secondary hover:bg-secondary-900 disabled:bg-neutral-400 disabled:hover:bg-neutral-400',
      textColor: 'text-white disabled:text-white',
    },
    type: {
      invisible: {
        base: 'bg-transparent border-solid border-secondary border-1 hover:bg-secondary-100 disabled:border-neutral-400 disabled:bg-transparent',
        textColor: 'text-secondary group-disabled:text-neutral-600',
      },
      ghost: {
        base: 'bg-white border-solid border-secondary border-1 hover:bg-secondary-100 disabled:border-neutral-400 disabled:bg-neutral-100',
        textColor: 'text-secondary group-disabled:text-neutral-600',
      },
    },
  },
});

export function Button({
  variant = 'default',
  icon: Icon,
  iconPosition = 'left',
  label,
  className,
  type = 'button',
  ...props
}) {
  const { base, textColor } = button({ type: variant });

  return (
    <button
      className={twMerge(
        'group',
        base({ class: className }),
        iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
      )}
      type={type}
      {...props}
    >
      {Icon && <Icon className={textColor()} size={22} />}
      <span className={twMerge('text-center !text-heading-3', textColor())}>
        {label}
      </span>
    </button>
  );
}
