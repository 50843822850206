import { BiSolidChevronRight } from 'react-icons/bi';
import Globals from '../components/Globals';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PiPlusCircleFill } from 'react-icons/pi';
import LoadingAnimation from '../components/LoadingAnimation';
import { MdOutlineHome } from 'react-icons/md';
import { PagePath } from '../pages-config';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import api from '@services/api';

export default function AdminUsuarios() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [typeAlert] = useState('');
  const [titleAlert] = useState('');
  const [txtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [listAgenciaCliente, seListAgenciaCliente] = useState([]);

  const { session } = useAuthentication();

  useEffect(() => {
    listaUsers();
    listaAgenciaCliente();
  }, []);

  function listaAgenciaCliente() {
    let query =
      'SELECT * FROM `vpcharter_contratante` ORDER BY `vpcharter_contratante`.`razaosocial` ASC ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          seListAgenciaCliente(response.data);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function listaUsers() {
    setLoading(true);
    let query =
      session.role === Role.CompradorAdministrador
        ? 'SELECT * FROM `vpcharter_users` WHERE agenciaCliente = ' +
          session.agency.id
        : 'SELECT * FROM `vpcharter_users` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(Globals.endPoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setUsers(response.data);
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function changeStatus(e, idUser) {
    setLoading(true);
    let query =
      "UPDATE `vpcharter_users` SET  `status` = '" +
      e +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function changeUserActive(e, idUser) {
    setLoading(true);
    let newUserActive = '0';
    if (e == '0') {
      newUserActive = '1';
    }
    let query =
      "UPDATE `vpcharter_users` SET  `userActive` = '" +
      newUserActive +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function changeAgenciaCliente(e, idUser) {
    setLoading(true);
    let query =
      "UPDATE `vpcharter_users` SET  `agenciaCliente` = '" +
      e +
      "' WHERE `vpcharter_users`.`id` = '" +
      idUser +
      "'  ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then(() => {
        listaUsers();
      })
      .catch((error) => alert(error));
  }

  function createNewUser() {
    navigate(PagePath.AdminUsuariosNovo);
  }

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>
      <div className="breadCrumb mx-auto my-0">
        <Link to={PagePath.Admin} relative="path">
          <MdOutlineHome className="icoBread" />
          <BiSolidChevronRight className="caretRight" /> Configurações
        </Link>
        &nbsp;/&nbsp;Usuários
      </div>

      <div className="lineButtons">
        <div className="col">
          <h1>Usuários</h1>
        </div>
        <div className="rightBtn">
          <button type="button" className="btn btnNew" onClick={createNewUser}>
            Novo <PiPlusCircleFill />
          </button>
        </div>
        <div className="btnTable">
          {/*
              <select onChange={(e) => changeLimit(e.target.value)} className={dataTotal > 25 ? 'selectLimit' : 'hidden'}>
                  <option value="25">25</option>
                  {dataTotal > 50 ? <option value="50">50</option> : undefined}
                  {dataTotal > 100 ? <option value="100">100</option> : undefined}
                  {dataTotal > 250 ? <option value="250">250</option> : undefined}
                  {dataTotal > 500 ? <option value="500">500</option> : undefined}
              </select>
              <div className="tdtoal">{startQuery+1} - {newStartQuery}  de  {formatNumber(dataTotal)}</div>
              <button type="button" className="btn btn-light m-1" onClick={() => lessData()} disabled={startQuery==0 ? true : false}><BsChevronLeft /></button>
              <button type="button" className="btn btn-light m-1" onClick={() => moreData()} disabled={newStartQuery==dataTotal ? true : false}><BsChevronRight /></button>
              <button onClick={() => onTableChange()} type="button" className="btn btn-light m-1 nomob">{tdShort ? <RiExpandLeftRightFill title="Expandir" /> : <RiContractLeftRightFill title="Contrair" />}</button>*/}
        </div>
      </div>

      <div className="contentAll">
        <div className="row">
          <div className="col-12 table_list">
            <table className="tblDefault">
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>E-mail</td>
                  <td>Status</td>
                  <td>Empresa</td>
                  <td className="text-center">Ativo</td>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>
                      {user.firstName} {user.familyName}
                    </td>
                    <td>{user.email}</td>
                    <td className="text-center">
                      <select
                        value={user.status}
                        onChange={(e) => changeStatus(e.target.value, user.id)}
                        required
                      >
                        <option value="">--</option>
                        {session.role === Role.Administrador && (
                          <>
                            <option value="0">Operacional</option>
                            <option value="1">Financeiro</option>
                            <option value="2">Produtos internos</option>
                            <option value="3">Comprador</option>
                            <option value="9">Gestão</option>
                            <option value="99">Administrador</option>
                            <option value="100">Comprador Administrador</option>
                          </>
                        )}
                        {session.role === Role.CompradorAdministrador && (
                          <>
                            <option value="3">Comprador</option>
                            <option value="100">Comprador Administrador</option>
                          </>
                        )}
                      </select>
                    </td>
                    <td>
                      {session.role === Role.CompradorAdministrador ? (
                        <span>{session.agency.nomeFantasia}</span>
                      ) : (
                        <select
                          value={user.agenciaCliente}
                          onChange={(e) =>
                            changeAgenciaCliente(e.target.value, user.id)
                          }
                        >
                          <option value="">-</option>
                          {listAgenciaCliente.map((data, index) => (
                            <option key={index} value={data.id}>
                              {data.razaosocial}
                            </option>
                          ))}
                        </select>
                      )}

                      {/*
                          <select value={user.status} onChange={(e)=>changeStatus(e.target.value, user.id)}  required >
                            <option value="0">Viagens Promo</option>
                          </select>
                            */}
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name="scales"
                        value={user.userActive}
                        checked={user.userActive == '0' ? true : false}
                        onChange={(e) =>
                          changeUserActive(e.target.value, user.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
