import { TravelerType } from '@system/enums/traveler-type';

export const convertTravelerType = (value) => {
  const result = {
    [TravelerType.Adult]: 'Adulto',
    [TravelerType.Children]: 'Criança',
    [TravelerType.Infant]: 'Infante',
  };
  return result[value] ?? '';
};
