import Header from '@components/Header';
import Sidebar from '@components/Sidebar';

export function Dashboard({ children }) {
  return (
    <div className="flex h-screen w-full flex-col">
      <Header />
      <div className="flex">
        <Sidebar />
        <div className="w-full overflow-auto p-5">{children}</div>
      </div>
    </div>
  );
}
