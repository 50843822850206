import { Role } from '@system/acl';

export const PagePath = {
  Home: '/home',
  RecuperarSenha: '/recuperar-senha',
  NovaSenha: '/nova-senha',
  NovoUsario: '/novo-usuario',
  MeusDados: '/meus-dados',
  Aereo: '/aereo',
  AereoFrota: '/aereo/frota',
  AereoFrotaCadastrar: '/aereo/frota/cadastrar',
  AereoFrotaEditar: '/aereo/frota/:id/editar',
  AereoFrotaAssentos: '/aereo/frota/:id/assentos',
  AereoFrotaRotas: '/aereo/frota/:id/rotas',
  AereoSaidas: '/aereo/saidas',
  AereoSaidasCadastrar: '/aereo/saidas/cadastrar',
  AereoSaidasEditar: '/aereo/saidas/:id/editar',
  AereoSaidasPrecificar: '/aereo/saidas/:id/precificar',
  AereoReservas: '/aereo/reservas',
  AereoReservasSelecionar_Legacy: '/aereo/reservas/:id/selecionar',
  AereoReservasConfirmar_Legacy: '/aereo/reservas/:id/confirmar',
  AereoReservasCheckout: '/aereo/reservas/checkout',
  AereoReservasPagamento: '/aereo/reservas/pagamento',
  AereoReservasConfirmar: '/aereo/reservas/confirmar',
  AeroCheckin: '/aereo/checkin',
  AeroCheckinLocalizador: '/aereo/checkin/:id/localizador',
  Hotel: '/hotel',
  Rodoviario: '/rodoviario',
  PacoteFixo: '/pacote-fixo',
  Admin: '/admin',
  AdminRegrasTarifarias: '/admin/regras-tarifarias',
  AdminAgencias: '/admin/agencias',
  AdminCompanhias: '/admin/companhias',
  AdminAeroportos: '/admin/aeroportos',
  AdminAssentos: '/admin/assentos',
  AdminClasses: '/admin/classes',
  AdminFabricantes: '/admin/fabricantes',
  AdminServicosBordo: '/admin/servicos-bordo',
  AdminUsuarios: '/admin/usuarios',
  AdminUsuariosNovo: '/admin/usuarios/novo',
  Relatorios: '/relatorios',
  Relatorio: '/relatorio',
  Financeiro: '/financeiro',
  ReservasExternasPesquisar: '/pesquisar-reservas-externas',
};

export const pagesACL = {
  [PagePath.Aereo]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AereoFrota]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoFrotaCadastrar]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoFrotaEditar]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoFrotaAssentos]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoFrotaRotas]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoSaidas]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoSaidasCadastrar]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoSaidasEditar]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoSaidasPrecificar]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
  ],
  [PagePath.AereoReservas]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AereoReservasCheckout]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AereoReservasPagamento]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AereoReservasSelecionar_Legacy]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AereoReservasConfirmar_Legacy]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AeroCheckin]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.AeroCheckinLocalizador]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.Hotel]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.Rodoviario]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.PacoteFixo]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Operacional,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.Admin]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Financeiro,
    Role.Gestao,
    Role.CompradorAdministrador,
  ],
  [PagePath.AdminRegrasTarifarias]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminAgencias]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Gestao,
    Role.Comprador,
  ],
  [PagePath.AdminCompanhias]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminAeroportos]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminAssentos]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminClasses]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminFabricantes]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminServicosBordo]: [Role.Administrador, Role.ProdutosInternos],
  [PagePath.AdminUsuarios]: [
    Role.Administrador,
    Role.Financeiro,
    Role.CompradorAdministrador,
  ],
  [PagePath.AdminUsuariosNovo]: [
    Role.Administrador,
    Role.Financeiro,
    Role.CompradorAdministrador,
  ],
  [PagePath.Relatorios]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Financeiro,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.Relatorio]: [
    Role.Administrador,
    Role.ProdutosInternos,
    Role.Financeiro,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.Financeiro]: [
    Role.Administrador,
    Role.Financeiro,
    Role.Comprador,
    Role.CompradorAdministrador,
  ],
  [PagePath.ReservasExternasPesquisar]: [Role.Administrador, Role.Operacional],
};
