import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert } from '@components/alert';
import { Button } from '@components/button';
import { InputRadio } from '@components/input';
import Tag from '@components/Tag';
import { useBookings } from '@stores/bookings';
import { useCreditAllotment } from '@stores/credit-allotment';
import { formatCurrency } from '@system/utils';
import { PagePath } from '../pages-config';

//TODO: refatorar para enums
const PaymentMethod = {
  Billing: 'billing',
  CreditCard: 'credit-card',
  Pix: 'pix',
  VPCard: 'vp-card',
};
export default function AereoReservaPagamento() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { state } = useLocation();
  const { travelers } = useBookings();
  const { insufficientCredit, insufficientAllotment, methods } =
    useCreditAllotment();

  const total = (travelers ?? [])
    ?.map((t) => Number(t.value))
    .reduce((prev, cur) => prev + cur, 0);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const goToVPCard = () => {
    window.open('https://landing.clara.com/faturepag', '_blank');
  };
  const nextStep = () => {
    if (insufficientAllotment || insufficientCredit) return;
    navigate(PagePath.AereoReservasConfirmar, {
      state: { ...state, paymentMethod },
    });
  };

  useEffect(() => {
    if (paymentMethod === PaymentMethod.Billing)
      methods.simulateUse({
        credit: total,
        allotment: travelers.length,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col rounded-lg bg-white   sm:p-4">
        <div className=" flex w-full flex-col ">
          <span className="text-heading-2 text-primary">
            Como será o pagamento?
          </span>
          <span className="mt-3 text-body font-normal">
            Escolha a forma de pagamento.
          </span>
        </div>
        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Formas de pagamento
          </div>
          <div className="mt-2 flex flex-col gap-y-8 pt-3">
            <div className="flex shrink-0 flex-row items-center gap-x-3">
              <InputRadio
                label="Faturamento"
                name="billing"
                value={PaymentMethod.Billing}
                checked={paymentMethod === PaymentMethod.Billing}
                onChange={handlePaymentMethodChange}
              />
            </div>
            <div className="flex shrink-0 flex-row items-center  gap-x-3">
              <InputRadio
                label="Cartão de Crédito"
                name="billing"
                value={PaymentMethod.CreditCard}
                disabled
              />
              <Tag value="Em Breve" />
            </div>
            <div className=" flex shrink-0 flex-row items-center  gap-x-3">
              <div className="flex flex-row items-center justify-center gap-x-3">
                <InputRadio
                  label="VP Card"
                  name="billing"
                  value={PaymentMethod.VPCard}
                  disabled
                />
                <Tag value="Em Breve" />
              </div>
              <div className="relative ml-4 inline-flex">
                <Button
                  label="Solicite agora o seu"
                  variant="ghost"
                  className="absolute -top-7  !m-0 inline-flex !py-2.5 px-3"
                  onClick={goToVPCard}
                />
              </div>
            </div>
            <div className="gapx-2 flex shrink-0 flex-row items-center gap-x-3">
              <InputRadio
                label="Pix"
                name="billing"
                value={PaymentMethod.Pix}
                disabled
              />
              <Tag value="Em Breve" />
            </div>
          </div>
        </div>

        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Faturamento
          </div>
          <div className="mt-2 flex flex-col gap-y-4 pt-3">
            <div className="text-heading-3 font-semibold text-price">
              Valor total: {formatCurrency(total)}
            </div>
          </div>
        </div>
        {(insufficientAllotment || insufficientCredit) && (
          <div className="mt-4">
            <Alert type="error">
              A agência não tem créditos suficiente para fazer essa reserva.
              <br /> Entre em contato com a ViagensPromo e solicite a aprovação
              de mais créditos.
            </Alert>
          </div>
        )}
      </div>
      <div className="mt-4 flex w-full flex-col ">
        <div className="flex flex-row justify-between">
          <Button
            label="Voltar"
            type="button"
            variant="ghost"
            className="w-2/12"
            onClick={() => {
              methods.getLimits();
              navigate(-1);
            }}
          />
          <Button
            label="Continuar"
            type="button"
            className="w-2/12"
            disabled={!paymentMethod}
            onClick={nextStep}
          />
        </div>
      </div>
    </div>
  );
}
