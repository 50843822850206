import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { formatToBRTz } from './utils';

export const MonthLabel = ({ date = new Date() }) => {
  return (
    <div className="basis-1/2 text-center">
      <span className="text-body font-semibold">
        {formatToBRTz(date, 'MMMM')}
      </span>
      <span className="block text-small">{date.getFullYear()}</span>
    </div>
  );
};

export const MonthLabelContainer = ({ children, onPrevClick, onNextClick }) => (
  <div className="flex border-0 border-y border-solid border-neutral-300 bg-neutral-100 px-5 py-2">
    <div role="button" className="flex items-center" onClick={onPrevClick}>
      <CgChevronLeft size={24} className="text-neutral-800" />
    </div>
    {children}
    <div role="button" className="flex items-center" onClick={onNextClick}>
      <CgChevronRight size={24} className="text-neutral-800" />
    </div>
  </div>
);
