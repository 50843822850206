import { useState, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GoAlertFill } from 'react-icons/go';
import { IoAirplaneOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import { DropdownListRoutes } from '@components/dropdown-list-routes';
import { InputText, InputDate } from '@components/input';
import { Modal } from '@components/modal';
import { RouteCard } from '@components/route-card';
import { Select } from '@components/select';
import { Switch } from '@components/switch';
import { Tab } from '@components/tab';
import Tag from '@components/Tag';
import api from '@services/api';
import { useAirports } from '@stores/airports';
import { useAuthentication } from '@stores/authentication';
import { toaster } from '@system/utils/toaster';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoSaidasCadastrar() {
  const { session } = useAuthentication();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [contratante, setContratante] = useState('');
  const [dataBaseRotasOrigem, setDataBaseRotasOrigem] = useState([]);
  const { methods } = useAirports();

  async function getRoutesInformations() {
    setLoading(true);
    const queryRoutes = `
      SELECT 
          r.id AS id_rota,
          r.outrodia,
          c.nome AS nomeCia,
          c.logo,
          f.aeronave,
          ft.id AS idAeronave,
          r.origem,
          a.nome,
          a.cidade,
          a.uf,
          r.destino,
          r.saida,
          r.duracao,
          r.chegada,
          r.voo,
          r.escala,
          r.conexao,
          r.operadopor
      FROM 
          vpcharter_rotas r
      INNER JOIN 
          vpcharter_frota ft ON ft.id = r.aeronave
      INNER JOIN 
          vpcharter_companhia c ON c.id = ft.companhia
      INNER JOIN 
          vpcharter_fabricante f ON f.id = ft.fabricante
      INNER JOIN 
          vpcharter_aeroportos a ON a.iata = r.origem
      WHERE 
          r.ativo = 1;
    `;
    const formDataRoutes = new FormData();
    formDataRoutes.append('query', queryRoutes);

    try {
      const { data } = await api.post('/query', formDataRoutes);
      setDataBaseRotasOrigem(data);
    } catch (error) {
      toaster('error', 'Não foi possível carregar as rotas');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRoutesInformations();
    methods.getAirports();
  }, []);

  const today = new Date();

  const [departureOrigin, setDepartureOrigin] = useState('');
  const [returnOrigin, setReturnOrigin] = useState('');
  const [currentIndex, setIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState({
    opened: false,
    isOnlyOneExcerpt: false,
  });

  const handleChange = (newIndex) => setIndex(newIndex);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      quantity: 0,
      active: true,
    },
  });
  const {
    control: controlOnlyOneExcerpt,
    handleSubmit: handleSubmitOnlyOneExcerpt,
    setValue: setValueOnlyOneExcerpt,
  } = useForm({
    defaultValues: {
      quantityOnlyOneExcerpt: 0,
      activeOnlyOneExcerpt: true,
    },
  });

  const quantity = useMemo(
    () =>
      Array.from({ length: 3 }).map((_, index) => {
        return {
          value: index,
        };
      }),
    [],
  );

  function handleSubmitDepartureAndReturn(payload) {
    const selectedDepartureDate = dayjs(payload.departureDate).add('1', 'day');
    const selectedReturnDate = dayjs(payload.returnDate);

    if (selectedDepartureDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    } else if (new Date(selectedReturnDate) < selectedDepartureDate) {
      return toaster(
        'warning',
        'A data de volta não pode ser menor que a data de ida.',
      );
    }

    let query = `
      INSERT INTO vpcharter_fretamento 
        (id, rota_ida, rota_volta, data_frete, date_volta, contratante, bagagem, ativo) 
      VALUES 
        (NULL, 
        '${payload.departureRoute.id_rota}', 
        '${payload.returnRoute.id_rota}', 
        '${payload.departureDate}', 
        '${payload.returnDate}', 
        '${session.agency.id}', 
        '${payload?.quantity}-${payload?.pound ?? ''}', 
        ${payload.active ? 1 : 0});
    `;

    let fData = new FormData();
    fData.append('query', query);

    api
      .post('/query', fData)
      .then((response) => {
        if (response.status === 201) {
          toaster('success', 'Saída cadastrada com sucesso!');
          let query = `
            SELECT * FROM vpcharter_fretamento 
            WHERE rota_ida = '${payload.departureRoute.id_rota}' 
              AND rota_volta = '${payload.returnRoute.id_rota}' 
              AND data_frete = '${payload.departureDate}' 
              AND date_volta = '${payload.returnDate}';
          `;
          let fData = new FormData();
          fData.append('query', query);
          api
            .post('/query', fData)
            .then((response) => {
              if (response.data.length > 0) {
                let idfret = response.data[0]['id'];
                setTimeout(
                  () =>
                    navigate(
                      PagePath.AereoSaidasPrecificar.replace(':id', idfret),
                    ),
                  2000,
                );
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => console.log(error));
  }

  function submitOnlyOneExcerpt(payload) {
    const selectedExcerptDate = dayjs(payload.excerptDate).add('1', 'day');

    if (selectedExcerptDate <= today) {
      return toaster(
        'warning',
        'Por favor, selecione uma data de ida posterior ou igual a hoje.',
      );
    }

    let query = `
      INSERT INTO vpcharter_fretamento 
        (id, rota_ida, data_frete, contratante, bagagem, ativo) 
      VALUES 
        (NULL, 
        '${payload.excerptRoute.id_rota}', 
        '${payload.excerptDate}', 
        '${session.agency.id}', 
        '${payload.quantityOnlyOneExcerpt}-${payload.poundOnlyOneExcerpt}', 
        ${payload.activeOnlyOneExcerpt ? 1 : 0});
    `;
    let formData = new FormData();
    formData.append('query', query);

    api
      .post('/query', formData)
      .then((response) => {
        if (response.status === 201) {
          toaster('success', 'Saída cadastrada com sucesso!');
          let query =
            "SELECT * FROM `vpcharter_fretamento` where `rota_ida` = '" +
            payload.excerptRoute.id_rota +
            "' and `data_frete` = '" +
            payload.excerptDate +
            "' ";

          let getQueryFormData = new FormData();
          getQueryFormData.append('query', query);

          api
            .post('/query', getQueryFormData)
            .then((response) => {
              if (response.data.length > 0) {
                let idfret = response.data[0]['id'];
                setTimeout(
                  () =>
                    navigate(
                      PagePath.AereoSaidasPrecificar.replace(':id', idfret),
                    ),
                  2000,
                );
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => console.log(error));
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  const confirmInactivate = () => {
    if (modalOpen.isOnlyOneExcerpt) {
      setValueOnlyOneExcerpt('activeOnlyOneExcerpt', false);
    } else {
      setValue('active', false);
    }
    setModalOpen({
      opened: false,
      isOnlyOneExcerpt: modalOpen.isOnlyOneExcerpt,
    });
  };

  function handleChangeStatus(checked, isOnlyOne) {
    if (checked) {
      setModalOpen({ opened: true, isOnlyOneExcerpt: isOnlyOne });
    } else if (isOnlyOne) {
      return setValueOnlyOneExcerpt('activeOnlyOneExcerpt', true);
    } else {
      return setValue('active', true);
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-7 pb-6">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Saídas', link: PagePath.AereoSaidas },
            { label: 'Cadastro de saídas', link: PagePath.Aereo },
          ]}
        />
        <h1 className="m-0 text-heading-1 text-primary">Cadastro de saídas</h1>
      </div>
      <div className="flex flex-col gap-4">
        <div className="w-full rounded-md bg-white">
          <Tab value={currentIndex} onChange={handleChange}>
            <Tab.Heads>
              <Tab.Item label="Ida e volta" index={0} />
              <Tab.Item label="Somente 1 trecho" index={1} />
            </Tab.Heads>
            <Tab.ContentWrapper>
              <Tab.Content index={0}>
                <form
                  onSubmit={handleSubmit(handleSubmitDepartureAndReturn)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Rota de ida
                        </div>
                      }
                    />
                    <Controller
                      name="departureRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="departureDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de ida"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            type="date"
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          {' '}
                          <IoAirplaneOutline className="flip" /> Rota de volta
                        </div>
                      }
                    />
                    <Controller
                      name="returnRoute"
                      control={control}
                      render={({ field }) =>
                        field.value ? (
                          <div className="flex flex-col gap-2">
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </div>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={returnOrigin}
                            label="Rota"
                            onChange={(text) => setReturnOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="returnDate"
                        control={control}
                        render={({ field }) => (
                          <InputDate
                            label="Data de volta"
                            onChange={(event) =>
                              field.onChange(event.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border  !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantity"
                          control={control}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="pound"
                          control={control}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, false)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
              <Tab.Content index={1}>
                <form
                  onSubmit={handleSubmitOnlyOneExcerpt(submitOnlyOneExcerpt)}
                  className="flex flex-col gap-[15px]"
                >
                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-4">
                    <Tag
                      value={
                        <div className="flex items-center gap-1">
                          <IoAirplaneOutline /> Trecho
                        </div>
                      }
                    />
                    <Controller
                      name="excerptRoute"
                      control={controlOnlyOneExcerpt}
                      render={({ field }) =>
                        field.value ? (
                          <>
                            <label className="m-0 text-sm font-medium text-neutral-800">
                              Rota
                            </label>
                            <RouteCard
                              data={field.value}
                              onClick={() => field.onChange(null)}
                            />
                          </>
                        ) : (
                          <DropdownListRoutes
                            placeholder="Digite a IATA, Cidade ou Estado"
                            dataSearch={dataBaseRotasOrigem}
                            value={departureOrigin}
                            label="Rota"
                            onChange={(text) => setDepartureOrigin(text)}
                            onSelectRoute={(data) => field.onChange(data)}
                          />
                        )
                      }
                    />
                    <div className="w-[196px]">
                      <Controller
                        name="excerptDate"
                        control={controlOnlyOneExcerpt}
                        render={({ field }) => (
                          <InputDate
                            label="Data do trecho"
                            onChange={(text) =>
                              field.onChange(text.target.value)
                            }
                            value={field.value}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-5 rounded-md !border !border-solid !border-neutral-300 p-[15px]">
                    <span className="text-base font-semibold text-primary">
                      Bagagem para despachar
                    </span>
                    <div className="flex w-full gap-4">
                      <div className="w-[129px]">
                        <Controller
                          name="quantityOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <Select
                              label="Quantidade"
                              value={field.value}
                              options={quantity}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                      <div className="w-[301px]">
                        <Controller
                          name="poundOnlyOneExcerpt"
                          control={controlOnlyOneExcerpt}
                          render={({ field }) => (
                            <InputText
                              label="Peso em kg"
                              placeholder="Insira o peso da bagagem em kg"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^[0-9,{1}]*$/.test(value)) {
                                  field.onChange(value);
                                }
                              }}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Controller
                    name="activeOnlyOneExcerpt"
                    control={controlOnlyOneExcerpt}
                    render={({ field }) => (
                      <Switch
                        label="Ativa"
                        checked={field.value}
                        onChange={() => handleChangeStatus(field.value, true)}
                      />
                    )}
                  />
                  <div className="flex w-full justify-end">
                    <Button
                      label="Salvar"
                      className="w-max px-[75px]"
                      type="submit"
                    />
                  </div>
                </form>
              </Tab.Content>
            </Tab.ContentWrapper>
          </Tab>
        </div>
      </div>
      <Modal open={modalOpen.opened}>
        <div className="flex flex-col items-center justify-center px-[190px] py-[60px]">
          <GoAlertFill className="text-warning-500" size={38} />
          <span className="mt-3 text-center text-heading-1 text-warning-500">
            Tem certeza que deseja inativar essa saída?
          </span>
          <p className="mb-[30px] mt-[15px] text-center text-heading-4 text-neutral-800">
            Ao inativar essa saída, ela será ocultada da listagem de
            disponibilidades de reservas.
          </p>
          <div className="mt-3 flex items-center justify-center gap-[30px]">
            <Button
              variant="ghost"
              label="Cancelar"
              onClick={() => setModalOpen((prev) => !prev)}
            />
            <Button label="Inativar saída" onClick={confirmInactivate} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
