import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { startOfToday, format, addDays } from 'date-fns';
import { unMask } from 'remask';
import { Alert } from '@components/alert';
import { Button } from '@components/button';
import { useAuthentication } from '@stores/authentication';
import { useBookings } from '@stores/bookings';
import { convertTravelerType } from '@system/converters/convertTravelerType';
import { BookingType, MetaSource } from '@system/enums';
import { formatCurrency } from '@system/utils';
import { toaster } from '@system/utils/toaster';

export default function AereoReservaConfirmar() {
  const navigate = useNavigate();
  const { session } = useAuthentication();
  const { travelers, methods } = useBookings();
  const { state: params } = useLocation();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const total = (travelers ?? [])
    ?.map((t) => Number(t.value))
    .reduce((prev, cur) => prev + cur, 0);

  const handleAcceptTerms = () => {
    if (!acceptTerms) setShowError(false);
    setAcceptTerms(!acceptTerms);
  };

  const createBooking = () => {
    try {
      if (!acceptTerms) {
        setShowError(true);
        return;
      }
      // Adiciona um dia à data de hoje e define a hora para 00:00:00
      const dueAt = addDays(startOfToday(new Date()), 1);

      methods.createBooking({
        value: total,
        type: BookingType.AR,
        metaSource: MetaSource.Platform,
        agencyId: Number(session.agency.id),
        charterId: params.charterId,
        dueAt: format(dueAt, 'yyyy-MM-dd HH:mm:ss'),
        travelers: travelers.map(
          ({
            birthdate,
            document,
            category,
            name,
            locator: _,
            ...traveler
          }) => {
            return {
              ...traveler,
              locator: '',
              firstName: name,
              seat: traveler.seat.join(''),
              value: Number(traveler.value),
              class: category,
              gender: traveler.gender.substring(0, 1),
              birthDate: format(birthdate, 'dd/MM/yyyy'),
              cpf: unMask(document),
            };
          },
        ),
      });
    } catch (err) {
      toaster('error', 'Não foi possível concluir a reserva');
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col rounded-lg bg-white   sm:p-4">
        <div className=" flex w-full flex-col ">
          <span className="text-heading-2 text-primary">
            Os dados estão corretos?
          </span>
          <span className="mt-3 text-body font-normal">
            Confirme os dados e conclua a sua reserva. Caso precise alterar
            algum dado, retorne para a etapa necessária.
          </span>
        </div>
        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Passageiros a confirmar{' '}
            {travelers?.length > 0 ? `(${travelers.length})` : ''}
          </div>
          <div className="mt flex flex-col gap-y-4 ">
            <ul className="m-0 flex list-none flex-col gap-y-3 p-0">
              {(travelers ?? []).map((traveler) => (
                <li
                  key={traveler?.id}
                  className="flex flex-row items-center justify-between gap-4  border-0 border-b border-solid border-neutral-300 px-3 py-6"
                >
                  <div className="flex h-[35px] w-[35px] flex-col items-center justify-center  self-start rounded-lg bg-primary p-[10px] text-center text-white">
                    {traveler?.seat}
                  </div>
                  <div className="flex w-full flex-col">
                    <span className="text-small font-bold text-primary ">
                      {convertTravelerType(traveler?.type)}
                    </span>
                    <span className="text-small font-normal">
                      {traveler?.name}
                    </span>
                  </div>
                  <div className="whitespace-nowrap text-right text-body font-bold text-price">
                    {formatCurrency(traveler?.value ?? 0)}
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-full"></div>
          </div>
        </div>

        <div className="mt-4  w-full items-center rounded-md border border-solid border-neutral-300 p-3 px-4">
          <div className="mt-2 w-full  border-0 !border-b border-solid border-neutral-300 pb-3 text-heading-3 font-semibold text-primary ">
            Formas de pagamento
          </div>
          <div className="mt-2 pt-3">
            <span className="text-body font-semibold text-neutral-800">
              Faturamento
            </span>
            <br />
            <span className="text-body font-normal text-neutral-800">
              Nosso financeiro vai entrar em contato e enviar todos os valores a
              serem pagos pela agência.
            </span>
          </div>
          <div className="mt-2 flex flex-col gap-y-4 pt-3">
            <div className="text-heading-3 font-semibold text-price">
              Valor total: {formatCurrency(total)}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <span className="flex items-center gap-2 text-body font-normal">
            <input
              type="checkbox"
              className="size-5 "
              checked={acceptTerms}
              onChange={handleAcceptTerms}
            />
            <span className="text-neutral-800">
              Li e aceito os{' '}
              <Link
                to="https://book2b-prod.s3.amazonaws.com/docs/Política+de+cancelamento.pdf"
                target="_blank"
              >
                Termos e Condições
              </Link>{' '}
              de compra.
            </span>
          </span>
        </div>

        {showError && (
          <div className="mt-4">
            <Alert type="warning">
              É necessário aceitar os termos para prosseguir.
            </Alert>
          </div>
        )}
      </div>
      <div className="mt-4 flex w-full flex-col ">
        <div className="flex flex-row justify-between">
          <Button
            label="Voltar"
            type="button"
            variant="ghost"
            className="w-2/12"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Button
            label="Concluir reserva"
            type="button"
            className="w-2/12"
            onClick={createBooking}
          />
        </div>
      </div>
    </div>
  );
}
