import { BiSolidChevronRight } from 'react-icons/bi';
import { MdOutlineHome } from 'react-icons/md';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { PiAirplaneTilt, PiCallBellLight } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { useAccessControl } from '@system/acl';
import { Menu } from '../components/menu/menu.jsx';
import { PagePath, pagesACL } from '../pages-config';

export default function Aereo() {
  const { hasPermission } = useAccessControl();

  return (
    <>
      <div className="breadCrumb">
        <Link to={PagePath.Home} relative="path">
          <MdOutlineHome className="icoBread" />
          <BiSolidChevronRight className="caretRight" /> Home
        </Link>
        &nbsp;/ Aéreo
      </div>
      <Menu submenu className={'pt-4'}>
        {hasPermission(pagesACL[PagePath.AereoFrota]) && (
          <Menu.Item
            to={PagePath.AereoFrota}
            title={'Frota'}
            icon={<PiAirplaneTilt className={'self-start'} />}
          />
        )}
        {hasPermission(pagesACL[PagePath.AereoSaidas]) && (
          <Menu.Item
            to={PagePath.AereoSaidas}
            title={'Saídas'}
            icon={<MdOutlineLocalOffer className={'self-start'} />}
          />
        )}
        <Menu.Item
          to={PagePath.AereoReservas}
          title={'Reservas'}
          icon={<PiCallBellLight className={'self-start'} />}
        />
      </Menu>
    </>
  );
}
