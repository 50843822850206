import { BsSuitcase2Fill } from 'react-icons/bs';
import { BsBagFill } from 'react-icons/bs';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaSuitcaseRolling } from 'react-icons/fa';
import { Button } from '@components/button';
import {
  hasBaggage,
  formatDateAereoReservas,
  formatTime,
  calculateFlightDuration,
  getAirportName,
  formatScale,
  formatBaggageByQuantityAndWeight,
} from '@system/utils';
import { Modal } from '.';

function formatCompanyName(company) {
  return company.split(' ')[0].toUpperCase() ?? '-';
}

export function ModalInfoBooking({ open, handleInactive, handleClose, props }) {
  const baggageValue = hasBaggage(props.baggage)
    ? formatBaggageByQuantityAndWeight(props.baggage)
    : '';
  return (
    <Modal open={open} handleClose={handleClose} size="big">
      <Modal.Header title="Dados do voo" handleClose={() => handleClose()} />
      <div className="!z-[99999] flex w-full flex-col rounded-md border border-solid border-[#EDEDED]">
        <div className="rounded-y-md flex w-full flex-row items-center justify-between bg-neutral-100 px-4 py-3 text-base font-semibold text-neutral-800">
          <span className="text-base">
            Voo de {props.isDeparture ? 'ida' : 'volta'}
          </span>
          <span className="text-sm">
            {formatScale(props.totalScale, props.totalConnection)}
          </span>
        </div>
        <div className="border-bottom border-0 border-solid border-[#EDEDED] px-4 py-2.5">
          <div>
            <span className="text-sm text-neutral-800">VOO 1</span>
          </div>
          <div className="grid grid-cols-1 grid-rows-4 md:grid-cols-4 md:grid-rows-1 md:pt-3">
            <div className="col-start-1 row-start-1 flex flex-col items-center gap-1.5 self-center text-sm text-neutral-800 md:col-start-1 md:row-start-1 md:items-start">
              <div className="flex flex-row items-center">
                <img className="w-9" src={props.companyLogo} alt="" />
                <span className="pl-1 text-xs">
                  {formatCompanyName(props.company)} - Voo: {props.flight}
                </span>
              </div>
              {props.operator && <span>Operado por {props.operator}</span>}
            </div>
            <div className="col-start-1 row-start-2 flex flex-col items-center gap-1.5 md:col-start-2 md:row-start-1">
              <span className="text-xs text-neutral-800">
                {formatDateAereoReservas(props.originDate)}
              </span>
              <span className="text-xl font-semibold text-neutral-800">
                {formatTime(props.originTime)?.replace('h', '')}
              </span>
              <span className="text-base font-semibold text-neutral-700">
                {props.origin}
              </span>
              <span className="text-center text-xs font-normal text-neutral-700">
                {getAirportName(props.airports, props.origin)}
              </span>
            </div>
            <div className="col-start-1 row-start-3 flex flex-col items-center gap-1.5 self-center text-neutral-800 md:col-start-3 md:row-start-1">
              <span className="text-xs">Duração</span>
              <CgArrowLongRight color="#E41047" />
              <span className="text-sm font-semibold">
                {calculateFlightDuration(
                  props.originTime,
                  props.originDate,
                  props.destinationTime,
                  props.destinationDate,
                )}
              </span>
            </div>
            <div className="col-start-1 row-start-4 flex flex-col items-center gap-1.5 md:col-start-4 md:row-start-1">
              <span className="text-xs text-neutral-800">
                {formatDateAereoReservas(props.destinationDate)}
              </span>
              <span className="text-xl font-semibold text-neutral-800">
                {formatTime(props.destinationTime)?.replace('h', '')}
              </span>
              <span className="text-base font-semibold text-neutral-700">
                {props.destination}
              </span>
              <span className="text-center text-xs font-normal text-neutral-700">
                {getAirportName(props.airports, props.destination)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 md:flex-row">
          <div>
            <span className="text-sm">Bagagens</span>
          </div>
          <div className="flex flex-col items-baseline gap-3 pt-5 text-left text-xs md:pl-12 md:pt-0">
            <div className="flex items-center gap-3">
              <BsBagFill size={12} color="#E41047" />
              <div className="flex flex-col">
                <span className="text-secondary">
                  Inclui uma mochila ou bolsa
                </span>
                <span>Deve caber embaixo do assento dianteiro.</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <FaSuitcaseRolling size={14} color="#E41047" />
              <div className="flex flex-col">
                <span className="text-secondary">Inclui bagagem de mão</span>
                <span>Deve caber no compartimento superior do avião.</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <BsSuitcase2Fill
                size={16}
                color={hasBaggage(props.baggage) ? '#E41047' : 'BFC4CD'}
              />
              <div className="flex flex-col">
                <span
                  className={hasBaggage(props.baggage) ? 'text-secondary' : ''}
                >
                  {hasBaggage(props.baggage)
                    ? `Inclui ${baggageValue.quantity} para despachar`
                    : 'Não inclui bagagem para despachar'}
                </span>
                <span>
                  {hasBaggage(props.baggage)
                    ? `A bagagem deve ter no máximo ${baggageValue.weight}.`
                    : 'Para adquirir bagagem, entrar em contato com a VP.'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button label="Fechar" variant="ghost" onClick={() => handleClose()} />
      </div>
    </Modal>
  );
}
