import { forwardRef, useId } from 'react';

export const InputText = forwardRef(function Input(
  {
    className,
    type = 'text',
    label,
    icon: Icon,
    iconPosition = 'left',
    ...props
  },
  ref,
) {
  const id = useId();
  const iconPositionClass =
    iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse';

  return (
    <div className="flex w-full flex-col items-start gap-2">
      {label && (
        <label
          htmlFor={id}
          className={`m-0 h-5 text-sm font-medium text-neutral-800`}
        >
          {label}
        </label>
      )}
      <div
        className={`
        has-[:focus]:border-1.5 flex h-12 w-full
        items-center justify-between rounded-md
        border border-solid border-neutral-600
        bg-transparent px-4 py-3.5 has-[:disabled]:border-transparent
        has-[:focus]:border-black
        has-[:disabled]:bg-neutral-300
        ${iconPositionClass}
        ${className}
        `}
      >
        {Icon && <Icon className="mr-2.5" size="20" />}
        <input
          {...props}
          id={id}
          ref={ref}
          type={type}
          className="!border-0 !p-0 text-sm font-normal outline-none placeholder:italic disabled:bg-neutral-300"
        />
      </div>
    </div>
  );
});
