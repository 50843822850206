import { useEffect, useState } from 'react';
import { BiSolidChevronRight } from 'react-icons/bi';
import { MdOutlineHome } from 'react-icons/md';
import { PiAirplane, PiCaretDownBold, PiCaretUpBold } from 'react-icons/pi';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import '../components/BoxCliente.css';
import BreadCrumbs from '@components/breadcrumbs';
import { InputText, InputDate } from '@components/input';
import LoadingAnimation from '@components/LoadingAnimation';
import RouteCardDetailed from '@components/route-card/RouteCardDetailed';
import { Select } from '@components/select';
import { useDebounce } from '@hooks/useDebounce';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import { PaymentStatus, PaymentMethod, PaymentCategory } from '@system/enums';
import {
  formatBaggage,
  formatCurrency,
  formatDate,
  formatTime,
} from '@system/utils';
import { toaster } from '@system/utils/toaster';
import { BsSuitcase } from 'react-icons/bs';
import { PagePath } from '../pages-config';

const ModalFaturamentoDetalhes = ({ data = null, onClose }) => {
  const formatAeroportoEscala = (
    aeroportoconexao,
    escalaorigem,
    escaladestino,
    totalconexao,
    totalescala,
  ) => {
    if (totalconexao > 1) {
      return totalconexao + ' conexões';
    } else if (totalescala > 1) {
      return totalescala + ' escalas';
    } else if (aeroportoconexao) {
      return aeroportoconexao;
    } else if (escalaorigem && escaladestino) {
      return escalaorigem + ' → ' + escaladestino;
    } else {
      return 'ˌ';
    }
  };
  function formatEscala(escala, conexao, totalescala, totalconexao) {
    if (totalescala == '1') {
      return 'Escala';
    } else if (totalescala > '1') {
      return 'Escalas';
    } else if (totalconexao == '1') {
      return 'Conexão';
    } else if (totalconexao > '1') {
      return 'Conexões';
    } else {
      return 'Direto';
    }
  }

  const [dataBaseAeroportos, setDataBaseAeroportos] = useState([]);

  function fetchAirports() {
    let query = 'SELECT * FROM `vpcharter_aeroportos` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setDataBaseAeroportos(response.data);
        }
      })
      .catch((error) => alert(error));
  }
  useEffect(() => {
    fetchAirports();
  }, []);

  return (
    data && (
      <div className="bg_loading">
        <div className="globalModal">
          <span className="closeModal" onClick={onClose}>
            ✕
          </span>

          <RouteCardDetailed
            baggage={data.bagagem}
            airports={dataBaseAeroportos}
            departureOperator={data.r1cia}
            departureFlight={data.r1voo}
            departureCompanyLogo={data.r1logo}
            departureOrigin={data.r1origem}
            departureOriginTime={data.r1saida}
            departureOriginDate={data.data_frete}
            departureDestination={data.r1destino}
            departureDestinationTime={data.r1chegada}
            departureDestinationDate={data.data_frete}
            returnOperator={data.r2cia}
            returnFlight={data.r2voo}
            returnCompanyLogo={data.r2logo}
            returnOrigin={data.r2origem}
            returnOriginTime={data.r2saida}
            returnOriginDate={data.date_volta}
            returnDestination={data.r2destino}
            returnDestinationTime={data.r2chegada}
            returnDestinationDate={data.data_frete}
            {...data}
          />
          {/* <div className="boxClienteModal row ">
            <div className="col p-0" onClick={() => {}}>
              <h5>
                {data.r1origem_cidade + '/' + data.r1origem_uf} ⇌{' '}
                {data.r2origem_cidade + '/' + data.r2origem_uf}
              </h5>
              <span className="h6">{formatDate(data.data_frete)}</span> a{' '}
              <span className="h6">{formatDate(data.date_volta)}</span>
            </div>
            <div className="ufOrigemDestino ufOrigemDestinoModal ">
              <div className="row ">
                <div className="col-6" onClick={() => {}}>
                  <div className="idaVolta">
                    <PiAirplane className="airplaneIda" /> Ida:{' '}
                    {formatDate(data.data_frete)}
                  </div>
                  <div className="lineLogo mt-3">
                    <img
                      className={data.r1cia ? 'imgCia' : 'hide'}
                      src={data.r1logo}
                    />{' '}
                    {data.r1logo ? '' : data.r1cia}
                    <small>
                      <span className={data.r1operadopor ? '' : 'hide'}>
                        Operado por {data.r1operadopor} -{' '}
                      </span>
                      Voo {data.r1voo}
                    </small>
                  </div>

                  <div className="rota">
                    <div className="product">
                      <div>
                        <b>{data.r1origem}</b> {formatTime(data.r1saida)}
                        <br />
                        <small>
                          {data.r1origem_cidade} / {data.r1origem_uf}
                        </small>
                      </div>
                    </div>
                    <div className="stoppingPoint">
                      <div className="text">
                        {formatEscala(
                          data.r1escala,
                          data.r1conexao,
                          data.r1totalescala,
                          data.r1totalconexao,
                        )}
                      </div>
                      <div className="linha">◉</div>
                      <div className="textIata">
                        {formatAeroportoEscala(
                          data.r1conexaoaeroporto,
                          data.r1escalaorigem,
                          data.r1escaladestino,
                          data.r1totalconexao,
                          data.r1totalescala,
                        )}
                      </div>
                    </div>
                    <div className="product">
                      <div>
                        <b>{data.r1destino}</b> {formatTime(data.r1chegada)}{' '}
                        <span
                          className={
                            data.r1outrodia == '1' ? 'oneMore' : 'hide'
                          }
                        >
                          +1
                        </span>
                        <br />
                        <small>
                          {data.r1destino_cidade} / {data.r1destino_uf}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 ">
                  <div className="idaVolta">
                    <PiAirplane className="airplaneVolta" /> Volta:{' '}
                    {formatDate(data.date_volta)}
                  </div>

                  <div className="lineLogo mt-3">
                    <img
                      className={data.r2cia ? 'imgCia' : 'hide'}
                      src={data.r2logo}
                    />{' '}
                    {data.r2logo ? '' : data.r2cia}
                    <small>
                      <span className={data.r2operadopor ? '' : 'hide'}>
                        Operado por {data.r2operadopor} -{' '}
                      </span>
                      Voo {data.r2voo}
                    </small>
                  </div>

                  <div className="rota">
                    <div className="product">
                      <div>
                        <b>{data.r2origem}</b> {formatTime(data.r2saida)}
                        <br />
                        <small>
                          {data.r2origem_cidade} / {data.r2origem_uf}
                        </small>
                      </div>
                    </div>
                    <div className="stoppingPoint">
                      <div className="text">
                        {formatEscala(
                          data.r2escala,
                          data.r2conexao,
                          data.r2totalescala,
                          data.r2totalconexao,
                        )}
                      </div>
                      <div className="linha">◉</div>
                      <div className="textIata">
                        {formatAeroportoEscala(
                          data.r2conexaoaeroporto,
                          data.r2escalaorigem,
                          data.r2escaladestino,
                          data.r2totalconexao,
                          data.r2totalescala,
                        )}
                      </div>
                    </div>
                    <div className="product">
                      <div>
                        <b>{data.r2destino}</b> {formatTime(data.r2chegada)}{' '}
                        <span
                          className={
                            data.r2outrodia == '1' ? 'oneMore' : 'hide'
                          }
                        >
                          +1
                        </span>
                        <br />
                        <small>
                          {data.r2destino_cidade} / {data.r2destino_uf}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={data.bagagem ? 'baggagediv' : 'hide'}>
                <BsSuitcase /> Bagagem para despachar:{' '}
                {formatBaggage(data.bagagem)}
              </div>
            </div>
          </div> */}

          <div className="row mt-2 pt-0">
            <div className="col-12 pt-0">
              <b>Passageiros</b>
              <div className="">
                <table className="tblPassageiros tblPassageirosModal text-start">
                  <thead>
                    <tr>
                      <td>Assento</td>
                      <td width="40%">Nome</td>
                      <td>Localizador</td>
                      <td>Valor</td>
                    </tr>
                  </thead>
                  <tbody>
                    {(data.passageiros ?? []).map((passageiro, index) => (
                      <tr key={index}>
                        <td>{passageiro.assento}</td>
                        <td>
                          {passageiro.nome} {passageiro.sobrenome}
                        </td>
                        <td>{passageiro.localizador}</td>
                        <td className="light-green">
                          {formatCurrency(passageiro.valor)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="3"></td>
                      <td>
                        <h6>
                          <b>
                            Valor Total:{' '}
                            <span className="light-green">
                              {formatCurrency(
                                (data.passageiros ?? [])
                                  ?.map((p) => Number(p.valor))
                                  .reduce((prev, cur) => prev + cur, 0),
                              )}
                            </span>
                          </b>
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const ModalPagamentoFaturamento = ({ data = null, onClose, setLoading }) => {
  const [datapagamento, setDataPagamento] = useState(null);
  const handlePay = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post(`/invoicing/${data.id}/pay`, {
        paymentDate: datapagamento,
      });
      setLoading(false);
      onClose();
      toaster('success', 'Alterado com sucesso!');
    } catch (err) {
      toaster('error', err?.message ?? 'Não foi possível pagar a fatura');
      setLoading(false);
    }
  };
  return (
    data && (
      <div className={'bg_loading'}>
        <div className="globalModal modalpeq text-center">
          <span className="closeModal" onClick={onClose}>
            ✕
          </span>

          <div className="row ">
            <div className="col-12">
              <h5>
                <b>Data do pagamento</b>
              </h5>
              Informe a data que foi feito o pagamento
            </div>

            <form onSubmit={(e) => handlePay(e)}>
              <div className="row  mt-3">
                <div className="col"></div>
                <div className="col-5">
                  <input
                    type="date"
                    value={datapagamento}
                    onChange={(e) => setDataPagamento(e.target.value)}
                    className="p-4 text-center"
                    required
                  />
                </div>
                <div className="col"></div>
              </div>
              <div className="row  mt-3">
                <div className="col"></div>
                <div className="col-5">
                  <button type="submit" className="p-4">
                    OK
                  </button>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default function Financeiro() {
  const [invoices, setInvoices] = useState([]);
  const [showBox, setShowBox] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    startPeriodPayment: '',
    endPeriodPayment: '',
    paymentMethod: '',
    paymentCategory: '',
    status: '',
    agency: '',
  });
  const [rowToPay, setRowToPay] = useState(null);
  const [rowSelected, setRowSelected] = useState(null);
  const handleDebounce = useDebounce(() => {
    // Perform search operation with the debounced term
    getInvoices();
  }, 900);

  const searchParams = new URLSearchParams(
    Object.keys(filters)
      .filter((i) => filters[i] && filters[i] != '')
      .reduce((prev, cur) => {
        let value = filters[cur];

        if (cur == 'paymentMethod') {
          value = PaymentMethod[filters[cur]];
        } else if (cur == 'paymentCategory') {
          value = PaymentCategory[filters[cur]];
        } else if (cur == 'status') {
          value = PaymentStatus[filters[cur]];
        }

        prev[cur] = value;
        return prev;
      }, {}),
  ).toString();
  const { session } = useAuthentication();

  const totalToPay = (invoices ?? [])
    .filter((invoice) => Number(invoice.status) === 9)
    .map((invoice) => Number(invoice.valor) ?? 0)
    .reduce((prev, cur) => prev + cur, 0);

  const qtyPaymentsPending = (invoices ?? [])
    .filter((invoice) => Number(invoice.status) === 9)
    .map(() => 1)
    .reduce((prev, cur) => prev + cur, 0);

  const getInvoices = async () => {
    setLoading(true);

    try {
      const { data } = await api.get(
        `/invoicing${searchParams ? `?${searchParams}` : ''}`,
      );
      setInvoices(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster(
        'error',
        error?.message ?? 'Não foi possível buscar o faturamento',
      );
    }
  };

  const handleSearch = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const methodPayment = Object.keys(PaymentMethod).map((key) => {
    return { value: key };
  });

  const categoryPayment = Object.keys(PaymentCategory).map((key) => {
    return { value: key };
  });

  const statusPayment = Object.keys(PaymentStatus).map((key) => {
    return { value: key };
  });

  const showInvoiceDetail = async (row) => {
    const reservaId = row.reservas.split(',')[0];
    setLoading(true);
    try {
      const { data } = await api.get(`/invoicing/${reservaId}`);
      setRowSelected(data);
      setLoading(false);
    } catch (err) {
      toaster(
        'error',
        err?.message ||
          'Não foi possível visualizar os detalhes do faturamento.',
      );
      setLoading(false);
    }
  };

  const invoicePay = async (row) => {
    setRowToPay(row);
  };

  useEffect(() => {
    if (Object.values(filters).filter((i) => i).length > 0) handleDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  useEffect(() => {
    getInvoices();
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <div className="!m-0 flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <BreadCrumbs
          paths={[
            { label: 'Home', link: PagePath.Home },
            { label: 'Financeiro', link: PagePath.Financeiro },
          ]}
        />

        <h1 className="m-0 text-heading-1 text-primary">Financeiro</h1>
      </div>

      <div className={session.role <= Role.Gestao ? 'boxCliente w-full' : ''}>
        {session.role <= Role.Gestao && (
          <>
            <div className="toggleBox" onClick={() => setShowBox(!showBox)}>
              {showBox ? <PiCaretUpBold /> : <PiCaretDownBold />}
            </div>
            <div
              className={
                'agenciaCliente row ' + (showBox ? 'agenciaClientVisible' : '')
              }
            >
              <div className="col">
                <h5 className="m-0">{session?.agency?.nomeFantasia}</h5>
              </div>
            </div>
          </>
        )}

        {((session.role <= Role.Gestao && showBox) ||
          session.role > Role.Gestao) && (
          <Container>
            <div id="toggleBoxTable" className="allotment col">
              <div className="allotmentItem">
                <div>
                  <RiMoneyDollarCircleLine className="creditIcon" />
                </div>
                <div className="allotmentItemContent itemFatura">
                  <h6>Reais presos para pagar</h6>
                  <h5 className="light-green">{formatCurrency(totalToPay)}</h5>
                </div>
              </div>

              <div className="allotmentItem">
                <div>
                  <RiMoneyDollarCircleLine className="creditIcon" />
                </div>
                <div className="allotmentItemContent itemFatura">
                  <h6>Pagamentos pendentes</h6>
                  <h5 className="light-green">{qtyPaymentsPending}</h5>
                </div>
              </div>
            </div>
          </Container>
        )}
      </div>

      <Container className="border-none bg-white">
        <div className="row items-center gap-y-5">
          <div className="col">
            <label
              className={`m-0 mb-2 h-5 text-sm font-medium text-neutral-800`}
            >
              Período de pagamento
            </label>
            <div className="row gap-y-3">
              <div className="col-6">
                <InputDate
                  onChange={(e) =>
                    handleSearch('startPeriodPayment', e.target.value)
                  }
                  value={filters?.startPeriodPayment}
                />
              </div>
              <div className="col-6">
                <InputDate
                  onChange={(e) =>
                    handleSearch('endPeriodPayment', e.target.value)
                  }
                  value={filters?.endPeriodPayment}
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <Select
              label="Método de pagamento"
              value={filters?.paymentMethod}
              options={methodPayment}
              onChange={(e) => handleSearch('paymentMethod', e)}
            />
          </div>
          <div className="col-2">
            <Select
              label="Categoria de pagamento"
              value={filters?.paymentCategory}
              options={categoryPayment}
              onChange={(e) => handleSearch('paymentCategory', e)}
            />
          </div>
          <div className="col-2">
            <Select
              label="Status"
              value={filters?.status}
              options={statusPayment}
              onChange={(e) => handleSearch('status', e)}
            />
          </div>
          {session.role != Role.CompradorAdministrador && (
            <div className={session.role >= Role.Gestao ? 'col-3' : 'hide'}>
              <InputText
                label="Agência"
                placeholder="Digite o nome da agência"
                onChange={(e) => handleSearch('agency', e.target.value)}
                value={filters?.agency}
              />
            </div>
          )}
        </div>
      </Container>

      <Container className="border-none bg-white">
        <div className="row">
          <div className="col-12 table_list">
            <h5>Pagamentos</h5>
            <table className="tblDefault overflow-x-auto">
              <thead>
                <tr>
                  <th scope="col">Nro reserva</th>
                  <th
                    scope="col"
                    className={session.role >= Role.Gestao ? '' : 'hide'}
                  >
                    Agência
                  </th>
                  <th scope="col">Método de pagamento</th>
                  <th scope="col">Categoria de pagamento</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="text-left">
                    Data pgto
                  </th>
                  <th scope="col">Valor</th>
                  <th
                    scope="col"
                    className={session.role >= Role.Gestao ? '' : 'hide'}
                    width="10%"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => (
                  <tr
                    key={invoice.id}
                    onClick={() => showInvoiceDetail(invoice)}
                  >
                    <td className="text-sm font-semibold text-secondary">
                      {invoice.codreserva}
                    </td>
                    <td>{invoice.nome_fantasia}</td>
                    <td>
                      {invoice.tipo == PaymentMethod.Faturamento
                        ? 'Faturamento'
                        : ''}
                    </td>
                    <td>
                      {Object.keys(PaymentCategory).find(
                        (category) =>
                          PaymentCategory[category] == invoice.category,
                      )}
                    </td>
                    <td>
                      {invoice.status == PaymentStatus.Pendente && (
                        <div className={'statusPgto statusPgto_9'}>
                          Pendente
                        </div>
                      )}
                      {invoice.status == PaymentStatus.Pago && (
                        <div className={'statusPgto statusPgto_12'}>Pago</div>
                      )}
                    </td>
                    <td className="text-left">
                      {formatDate(invoice.datapagamento)}
                    </td>
                    <td>{formatCurrency(invoice.valor)}</td>
                    <td>
                      {[Role.Financeiro, Role.Administrador].includes(
                        session.role,
                      ) && (
                        <button
                          className={
                            Number(invoice.status) === PaymentStatus.Pago
                              ? 'hide'
                              : 'btnWhite btnPeq'
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            invoicePay(invoice);
                          }}
                        >
                          Informar pagto
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
      {rowSelected && (
        <ModalFaturamentoDetalhes
          data={rowSelected}
          onClose={() => setRowSelected(null)}
        />
      )}
      {rowToPay && (
        <ModalPagamentoFaturamento
          setLoading={setLoading}
          data={rowToPay}
          onClose={() => {
            setRowToPay(null);
            getInvoices();
          }}
        />
      )}
    </div>
  );
}
