import { useState } from 'react';
import '../components/Login.css';
import { Link } from 'react-router-dom';
import api from '@services/api';

const NovoUsuario = () => {
  const [typeAlert] = useState('');
  const [alert] = useState(false);
  const [txtAlert] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [agency, setAgency] = useState('');
  const [titleAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const [envioEmail, setEnvioEmail] = useState(false);

  function validaForm(e) {
    e.preventDefault();
    setLoading(true);
    api
      .post('/leads', { name, email, agency })
      .then((response) => {
        if (response.status === 201) {
          setLoading(false);
          setEnvioEmail(true);
        }
      })
      .catch((error) => alert(error));
  }

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div className={alert ? 'alert ' + typeAlert : 'hide'} role="alert">
            <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
            {txtAlert}
          </div>

          <div className="book2bLogin" alt="book2b">
            {' '}
          </div>

          <form
            className={envioEmail ? 'hide' : 'inputFormLogin'}
            onSubmit={(e) => validaForm(e)}
          >
            <div>
              <h2>Saiba mais sobre a plataforma.</h2>
              <p>Por favor, digite seus dados.</p>
            </div>
            <div>
              <label>Nome Completo</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Digite seu nome completo"
                required
              ></input>

              <label>E-mail</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                required
              ></input>

              <label>Agência de Turismo</label>
              <input
                type="text"
                value={agency}
                onChange={(e) => setAgency(e.target.value)}
                placeholder="Digite o nome da Agência de Turismo"
              ></input>

              <button type="submit">
                <span className={loading ? 'hide' : ''}>Enviar</span>
                <span className={loading ? 'loader' : 'hide'}></span>
              </button>
            </div>
          </form>

          <div className={envioEmail ? 'mb-5 mt-5 ' : 'hide'}>
            <h2>Obrigado!</h2>
            <p></p>
            <p>Entraremos em contato em breve.</p>
            <div className="row ">
              <div className="col-4"></div>
              <div className="col-4">
                <Link to="/" relative="path">
                  <button type="button" className="btnPeq mt-0">
                    <span>Voltar</span>
                  </button>
                </Link>
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovoUsuario;
