import { useBookings } from '@stores/bookings';
import { convertTravelerType } from '@system/converters/convertTravelerType';
import { formatCurrency } from '@system/utils';

const ReservationTravelersList = () => {
  const { travelers, methods } = useBookings();
  const editTraveler = (info) => {
    methods.setTraveler(info);
    methods.updateModal('createTravelerVisible', true);
    const [column, row] = info.seat;
    methods.setSeat({
      column,
      row,
      category: info.category,
    });
  };
  return (
    <ul className="m-0 flex list-none flex-col gap-y-3 p-0">
      {(travelers ?? []).map((traveler) => (
        <li
          key={traveler?.id}
          onClick={editTraveler.bind(this, traveler)}
          className="flex cursor-pointer flex-row items-center justify-between gap-4 rounded-lg border border-solid border-neutral-300 p-3"
        >
          <div className="flex h-[35px] w-[35px] flex-col items-center justify-center  self-start rounded-lg bg-primary p-[10px] text-center text-white">
            {traveler?.seat}
          </div>
          <div className="flex w-full flex-col">
            <span className="text-small font-bold text-primary ">
              {convertTravelerType(traveler?.type)}
            </span>
            <span className="text-small font-normal">{traveler?.name}</span>
          </div>
          <div className="whitespace-nowrap text-right text-body font-bold text-price">
            {formatCurrency(traveler?.value ?? 0)}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ReservationTravelersList;
