export function Container({ children, className }) {
  return (
    <div
      className={
        'rounded-md border border-solid border-neutral-300 bg-white p-5 ' +
        className
      }
    >
      {children}
    </div>
  );
}
