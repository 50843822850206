import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuthentication } from '../stores/authentication';
import { Link, useNavigate } from 'react-router-dom';
import { PagePath } from '../pages-config';

const schemaValidation = yup.object().shape({
  email: yup.string().label('E-mail').required().email(),
  password: yup.string().label('Senha').required(),
});
const Login = () => {
  const navigate = useNavigate();
  const { loading, methods } = useAuthentication();
  const [txtError, setTxtError] = useState();
  const [titleError] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
    mode: 'all',
  });

  const onSubmit = async (formData) => {
    try {
      if (await methods.signIn(formData)) {
        navigate(PagePath.Home);
      }
    } catch (err) {
      setTxtError(err);
      setTimeout(() => setTxtError(''), 5000);
    }
  };

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div
            className={txtError ? 'alert alert-danger' : 'hide'}
            role="alert"
          >
            <h4 className={titleError ? '' : 'hide'}>{titleError}</h4>
            {txtError}
          </div>

          <div className="book2bLogin" alt="book2b">
            {' '}
          </div>

          <form className="inputFormLogin" onSubmit={handleSubmit(onSubmit)}>
            <label>Login</label>
            <input
              {...register('email')}
              className={errors.email ? 'error' : ''}
              type="text"
              placeholder="Digite seu e-mail"
            />

            <label>Senha</label>
            <input
              {...register('password')}
              className={errors.password ? 'error' : ''}
              type="password"
              placeholder="Digite sua Senha"
            />

            <div className="row lkLogin">
              <div className="col-6 lk1">
                <Link to={PagePath.RecuperarSenha} relative="path">
                  Esqueceu a senha?
                </Link>
              </div>
            </div>

            <button type="submit">
              <span className={loading ? 'hide' : ''}>Entrar</span>
              <span className={loading ? 'loader' : 'hide'}></span>
            </button>

            <div className="mt-2 text-center">
              <Link to={PagePath.NovoUsario} relative="path">
                <small>Quer saber mais sobre a plataforma?</small>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;
