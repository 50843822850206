import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { GoAlert } from 'react-icons/go';
import dayjs from 'dayjs';
import moment from 'moment';
import { mask, unMask } from 'remask';
import * as yup from 'yup';
import { Alert } from '@components/alert';
import { Button } from '@components/button';

import { InputDate, InputText } from '@components/input';
import { Modal } from '@components/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBookings } from '@stores/bookings';
import { FlightClassCategory } from '@system/enums';
import { formatBaggage, formatCurrency } from '@system/utils';

import { toaster } from '@system/utils/toaster';

const schema = yup.object().shape({
  nome: yup.string().required('Por favor, insira seu nome'),
  sobrenome: yup.string().required('Por favor, insira seu sobrenome'),
  email: yup
    .string()
    .email('Por favor, insira um email válido')
    .required('Por favor, insira seu email'),
  sexo: yup
    .string()
    .oneOf(['Masculino', 'Feminino'], 'Por favor, selecione um sexo')
    .required('Por favor, selecione um sexo'),
  telefone: yup.string().required('Por favor, insira seu telefone'),
  observacoes: yup.string(),
  cpf: yup.string().required('Por favor insira seu CPF'),
  data_nascimento: yup
    .string()
    .required('Por favor, insira sua data de nascimento'),
  tipo: yup.string().required('Por favor, selecione um tipo'),
});

const INITIAL_VALUES = {
  nome: '',
  sobrenome: '',
  data_nascimento: '',
  sexo: '',
  tipo: '',
  cpf: '',
  email: '',
  telefone: '',
  observacao: '',
};

export function ModalReservationCreatePassenger() {
  const {
    methods,
    modal,
    seat,
    flightDetail,
    tablePrice,
    traveler,

    seatClass,
    specialSeat,
    type,
    travelers,
  } = useBookings();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  function changeNascimento(e) {
    let numsStr = seatClass.replace(/[^0-9]/g, '');
    let fil = parseInt(numsStr);

    // Arrumar para apenas 1 trecho
    let d1 = moment(flightDetail.outboundDate, 'YYYY-MM-DD');
    let d2 = moment(getValues('data_nascimento'), 'YYYY-MM-DD');

    let diff = d1.diff(d2);
    let meses = moment.duration(diff).asMonths();

    let type = '';
    let isValidDate = false;

    if (meses < 23) {
      type = 'INF';
    } else if (meses >= 24 && meses < 133) {
      type = 'CHD';
    } else if (meses >= 134) {
      type = 'ADT';
    }

    if (type) {
      setValue('tipo', type);
      isValidDate = true;
      methods.setDateValidate(isValidDate);
    }
    //TODO: Rever essa validação.
    // if (
    //   checkSeatSpecial(
    //     seatClass,
    //     fil,
    //     totalQueue,
    //     saidaEmergPrimC,
    //     saidaEmergPremium,
    //     saidaEmergExecutiva,
    //     saidaEmergEconomica,
    //   ) &&
    //   type !== 'ADT'
    // ) {
    //   methods.setSeat({ specialSeat: true });
    // }
  }

  const convertSeatCategoryLabel = {
    [FlightClassCategory.Economy]: 'Econômica',
    [FlightClassCategory.Business]: 'Executiva',
    [FlightClassCategory.Premium]: 'Premium',
    [FlightClassCategory.FirstClass]: 'Primeira Classe',
  };

  function onSubmit(formData) {
    const selectedDate = new Date(formData.data_nascimento);
    const today = new Date();
    const minDate = new Date();
    minDate.setDate(today.getDate() - 1);
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() - 100);

    if (selectedDate > today) {
      setError('data_nascimento');
      return toaster(
        'warning',
        'Por favor, selecione uma data anterior ou igual a hoje.',
      );
    } else if (selectedDate < maxDate) {
      setError('data_nascimento');
      return toaster(
        'warning',
        'Por favor, selecione uma data dentro dos últimos 100 anos.',
      );
    }

    let cpfSemMascara = formData.cpf.replace(/[._-]/g, '');

    if (cpfSemMascara.length >= 11 && !traveler) {
      const cpfJaExiste = travelers.some(
        (traveler) => traveler.document === formData.cpf,
      );

      if (cpfJaExiste) {
        setError('cpf');
        return toaster(
          'error',
          'O CPF já foi cadastrado nesta reserva e não pode ser inserido novamente.',
        );
      }
    }
    const locator = `AFB${dayjs().format('DDMMYYHHmmss')}`;
    const valueByCategory =
      tablePrice[seat.category][formData.tipo.toLowerCase()];
    const value = valueByCategory;

    if (traveler) {
      methods.updateTravelerById(traveler.id, {
        document: formData.cpf,
        name: formData.nome,
        lastName: formData.sobrenome,
        birthdate: formData.data_nascimento,
        gender: formData.sexo,
        type: formData.tipo,
        phone: formData.telefone,
        email: formData.email,
        category: seat.category,
        note: formData.observacao,
        value,
      });
    } else if (!traveler) {
      methods.addTravelers({
        id: travelers.length + 1,
        locator,
        document: formData.cpf,
        name: formData.nome,
        lastName: formData.sobrenome,
        birthdate: formData.data_nascimento,
        gender: formData.sexo,
        type: formData.tipo,
        phone: formData.telefone,
        email: formData.email,
        seat: [seat.column, seat.row],
        category: seat.category,
        note: formData.observacao,
        value,
      });
    }

    onClose();
  }

  const deleteTraveler = () => {
    if (!traveler) return;
    methods.removeTravelers(traveler.id);
    methods.updateModal('createTravelerVisible', false);
    methods.setSeat(null);
    methods.setTraveler(null);
    reset(INITIAL_VALUES);
  };
  const onClose = () => {
    reset(INITIAL_VALUES);
    methods.setTraveler(null);
    methods.updateModal('createTravelerVisible', false);
    methods.setSeat(null);
  };

  const formatDocument = (e) => {
    const value = e.target.value;
    let clearMask = unMask(value);
    e.target.value = mask(clearMask, ['999.999.999-99']);
  };
  const formatPhone = (e) => {
    const value = e.target.value;
    let clearMask = unMask(value);
    e.target.value = mask(clearMask, ['', '(', '(99', '(99) 99999-9999']);
  };

  useEffect(() => {
    if (modal.createTravelerVisible && traveler) {
      reset({
        nome: traveler.name,
        sobrenome: traveler.lastName,
        data_nascimento: traveler.birthdate,
        sexo: traveler.gender,
        tipo: traveler.type,
        cpf: traveler.document,
        email: traveler.email,
        telefone: traveler.phone,
        observacao: traveler.note,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [traveler, modal.createTravelerVisible]);

  return (
    seat &&
    modal.createTravelerVisible && (
      <Modal open={modal.createTravelerVisible}>
        <Modal.Header
          title={
            getValues('tipo') === 'INF'
              ? 'Dados do passageiro Infante (0 a 23 meses)'
              : 'Dados do passageiro'
          }
          handleClose={() => onClose()}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full items-start gap-4"
        >
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full gap-4">
              <InputText
                placeholder="Insira o nome do passageiro"
                label="Nome"
                {...register('nome')}
                error={errors.nome && errors.nome.message}
              />
              <InputText
                placeholder="Insira o sobrenome do passageiro"
                label="Último sobrenome"
                {...register('sobrenome')}
                error={errors.sobrenome && errors.sobrenome.message}
              />
            </div>
            <div className="flex w-full gap-4">
              <div className="flex w-full flex-col gap-2">
                <label className="m-0  text-sm font-medium text-neutral-800">
                  Sexo
                </label>
                <select
                  placeholder="Escolha"
                  label="Sexo"
                  className="flex h-12 w-full items-center justify-between rounded-md border border-neutral-600 bg-transparent px-4 py-0  text-sm font-normal placeholder:italic"
                  {...register('sexo')}
                >
                  <option value="">Selecione</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>

                <div className="-mt-4 h-6 w-full">
                  {errors.sexo && (
                    <span
                      className={`flex items-center gap-1 text-sm font-normal text-error-900`}
                    >
                      {' '}
                      <GoAlert size={13} /> {errors.sexo.message}
                    </span>
                  )}
                </div>
              </div>
              <InputDate
                placeholder="Insira a data de nascimento"
                label="Data de nascimento"
                type="date"
                error={errors.data_nascimento && errors.data_nascimento.message}
                {...register('data_nascimento')}
                required
                onBlur={(e) => changeNascimento(e.target.value)}
              />
              <InputText
                placeholder="-"
                label="Tipo"
                disabled
                {...register('tipo')}
                error={errors.tipo && errors.tipo.message}
              />
            </div>
            <div className="flex w-full gap-4">
              <InputText
                placeholder="Insira um número do CPF"
                label="Número de CPF"
                {...register('cpf')}
                onChange={formatDocument}
                required
                error={errors.cpf && errors.cpf.message}
              />
              <InputText
                placeholder="Insira o telefone"
                label="Telefone"
                {...register('telefone')}
                onChange={formatPhone}
                required
              />
              <InputText
                placeholder="Insira o e-mail do passageiro"
                label="E-mail"
                {...register('email')}
              />
            </div>
            <InputText
              placeholder="Caso queira deixar alguma observação, insira aqui"
              label="Observações"
              {...register('observacao')}
            />
            <div className={`flex w-full justify-between`}>
              {traveler && (
                <Button
                  variant="ghost"
                  type="button"
                  className={`w-60`}
                  label="Excluir passageiro"
                  onClick={deleteTraveler}
                />
              )}
              <Button label="Salvar" type="submit" className="w-60" />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex w-[368px] flex-col gap-[10px] rounded-md border border-solid border-neutral-300 p-5">
              <h3 className="mb-1 text-base font-semibold text-neutral-800">
                Dados do Assento
              </h3>

              <div className="flex flex-col">
                <span className="text-base font-normal text-neutral-800">
                  Assento
                </span>
                <b className="text-base font-semibold text-neutral-800">
                  {seat ? `${seat.column}${seat.row}` : ''}
                </b>
              </div>
              <div className="flex flex-col">
                <span className="text-base font-normal text-neutral-800">
                  Classe
                </span>

                <b className="text-base font-semibold text-neutral-800">
                  {convertSeatCategoryLabel[seat.category] ?? ''}
                </b>
              </div>
              {flightDetail && flightDetail.baggage && (
                <div className="flex flex-col">
                  <span className="text-base font-normal text-neutral-800">
                    Bagagem despachada
                  </span>
                  <b className="text-base font-semibold text-neutral-800">
                    {formatBaggage(flightDetail.baggage)}
                  </b>
                </div>
              )}
              {traveler && (
                <div className="flex flex-col border border-x-0 border-t border-solid border-neutral-300 py-4">
                  <div className="flex flex-col">
                    <span className="text-base font-normal text-neutral-800">
                      Tipo
                    </span>
                    <b className="text-base font-semibold text-neutral-800">
                      {traveler.type}
                    </b>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-normal text-neutral-800">
                      Valor
                    </span>
                    <b className="text-base font-semibold text-price">
                      {formatCurrency(traveler.value ?? 0)}
                    </b>
                  </div>
                </div>
              )}
              {traveler && (
                <div className="flex flex-col">
                  <span className="text-base font-normal text-neutral-800">
                    Localizador
                  </span>
                  <b className="text-base font-semibold text-neutral-800">
                    {traveler.locator}
                  </b>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-4">
              <div
                className={`${type === 'CHD' || type === 'INF' ? (specialSeat ? 'hide' : 'fadein') : 'hide'}`}
              >
                <Alert type="warning">
                  O passageiro Infante poderá viajar somente no colo do adulto.
                </Alert>
              </div>
              {specialSeat && (
                <Alert className="w-[368px]" type="error">
                  Não é permitido menores de idade em um assento de saída de
                  emergencia
                  <br /> *Referente a idade que o passageiro terá na data da
                  volta da viagem.
                </Alert>
              )}
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}
