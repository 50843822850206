import { useEffect, useState } from 'react';
import LoadingAnimation from '../components/LoadingAnimation';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineHome } from 'react-icons/md';
import { BiSolidChevronRight } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import Globals from '../components/Globals';
import * as bcrypt from 'bcryptjs';
import dayjs from 'dayjs';
import { PagePath } from '../pages-config';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import api from '@services/api';

export default function AdminUsuariosNovo() {
  const [endpoint] = useState(Globals.endPoint);
  const [loading, setLoading] = useState(true);
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [listAgencia, setListAgencia] = useState([]);
  const navigate = useNavigate();
  const { session } = useAuthentication();

  async function listaAgenciaCliente() {
    let query =
      'SELECT * FROM `vpcharter_contratante` ORDER BY `vpcharter_contratante`.`razaosocial` ASC ';
    let fData = new FormData();
    fData.append('query', query);
    try {
      const { data } = await api.post(endpoint + '/query', fData);
      if (data.length > 0) {
        setListAgencia(data);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  }

  const validaForm = async (e) => {
    e.preventDefault();
    // Cria um objeto FormData com base no formulário
    let formData = new FormData(e.target);

    // Converte FormData para um objeto simples
    let form = {};
    formData.forEach(function (value, key) {
      form[key] = value;
    });

    let query =
      "SELECT * FROM `vpcharter_users` WHERE `email` = '" +
      form['email'] +
      "' ";
    let fData = new FormData();
    fData.append('query', query);

    try {
      //Verifica se o email ja existe
      const { data } = await api.post(endpoint + '/query', fData);
      if (data.length > 0) {
        setTypeAlert('alert-danger');
        settitleAlert('E-mail ja cadastrado!');
        setTimeout(() => setTypeAlert(''), 3000);
        return;
      }
      const hash = await bcrypt.hash(form['password'], 8);
      form['password'] = hash;

      query =
        "INSERT INTO `vpcharter_users` ( `name`, `familyName`, `firstName`, `status`, `pass`, `email`, `dateRegister`, `userActive`, `agenciaCliente`) VALUES ('" +
        form.nome +
        "','" +
        form.sobrenome +
        "','" +
        form.nome +
        "','" +
        form.status +
        "','" +
        form.password +
        "', '" +
        form.email +
        "', '" +
        dayjs().format('YYYY-MM-DD HH:mm:ss') +
        "', '" +
        form.ativo +
        "','" +
        form.empresa +
        "' );";

      fData = new FormData();
      fData.append('query', query);

      const response = await api.post(endpoint + '/query', fData);

      if (response.status === 201) {
        setTypeAlert('alert-success');
        settitleAlert('Sucesso!');
        setTxtAlert('Usuario criado com sucesso!');
        setTimeout(() => {
          setTypeAlert('');
          navigate(PagePath.AdminUsuarios);
        }, 3000);
      } else {
        setTypeAlert('alert-error');
        settitleAlert('Não foi possivel criar o usuario!');
        setTimeout(() => setTypeAlert(''), 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    listaAgenciaCliente();
  }, []);

  return (
    <div>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>
      <div className="breadCrumb mx-auto my-0">
        <Link to={PagePath.Home} relative="path">
          <MdOutlineHome className="icoBread" />
          <BiSolidChevronRight className="caretRight" /> Home
        </Link>
        &nbsp;/&nbsp;
        <Link to={PagePath.AdminUsuarios} relative="path">
          Usuários
        </Link>
        &nbsp;/&nbsp;Novo
      </div>
      <div className="lineButtons">
        <div className="col">
          <h1>Usuários</h1>
        </div>
      </div>
      <form
        className=" mx-auto pt-0"
        onSubmit={(e) => validaForm(e)}
        style={{ maxWidth: '1600px' }}
      >
        <div className="row">
          <div className="col-6">
            <label>Nome</label>
            <input name="nome" type="text" required />
          </div>
          <div className="col-6">
            <label>Sobrenome</label>
            <input name="sobrenome" type="text" required />
          </div>
          <div className="col-6">
            <label>E-mail</label>
            <input name="email" type="text" required />
          </div>
          <div className="col-6">
            <label>Senha</label>
            <input name="password" type="password" required />
          </div>
          <div className="col-6">
            <label>Status</label>
            <select name="status" required>
              <option value="">--</option>
              {session.role === Role.Administrador && (
                <>
                  <option value="0">Operacional</option>
                  <option value="1">Financeiro</option>
                  <option value="2">Produtos internos</option>
                  <option value="3">Comprador</option>
                  <option value="9">Gestão</option>
                  <option value="99">Administrador</option>
                  <option value="100">Comprador Administrador</option>
                </>
              )}
              {session.role === Role.CompradorAdministrador && (
                <>
                  <option value="3">Comprador</option>
                  <option value="100">Comprador Administrador</option>
                </>
              )}
            </select>
          </div>
          <div className="col-6">
            <label>Empresa</label>
            <select name="empresa">
              <option value="">-</option>
              {listAgencia.map(
                (data) =>
                  (session.role === Role.Administrador ||
                    (session.role === Role.CompradorAdministrador &&
                      data.nome_fantasia === session.agency.nomeFantasia)) && (
                    <option key={data.id} value={data.id}>
                      {data.razaosocial}
                    </option>
                  ),
              )}
            </select>
          </div>

          <div className="col-6">
            <label>Ativo</label>
            <select name="ativo" required>
              <option value="0">Sim</option>
              <option value="1">Não</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <button type="submit" disabled={typeAlert === 'alert-success'}>
              <span className={loading ? 'hide' : ''}>
                <FiEdit /> Salvar
              </span>
              <span className={loading ? 'loader' : 'hide'}></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
