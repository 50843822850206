import { create } from 'zustand';
import api from '@services/api';

export const useBookings = create((set, get) => ({
  booking: null,
  travelers: [],
  traveler: null,
  step: 'checkout',
  modal: {
    createTravelerVisible: false,
    bookingConfirmed: false,
  },
  loading: false,
  flightDetail: null,
  seat: null,
  tablePrice: null,

  //TODO: Remover quando refatorar airplane-reservation
  opened: false,
  seatClass: '',
  specialSeat: false,
  dateValidate: false,
  hasEnoughAllotments: true,
  hasEnoughCredits: true,
  type: null,
  actualStatus: '',
  reservationInformations: {},
  routeInformations: {},
  totalQueue: 0,
  saidaEmergPrimC: [],
  saidaEmergPremium: [],
  saidaEmergExecutiva: [],
  saidaEmergEconomica: [],
  reservationIds: null,
  seatValue: null,

  methods: {
    /**
     * Retrieves flight details for a given charter ID.
     *
     * @param {string} charterId - The ID of the charter.
     * @return {Promise<Object>} A promise that resolves to the flight details data.
     */
    getFlightDetails: async (charterId) => {
      set({ loading: true });
      const { data } = await api.get(`/charters/${charterId}`);
      set({ flightDetail: data, loading: false });
      return data;
    },
    createBooking: async (params) => {
      const { data } = await api.post('/bookings', params);
      get().methods.updateModal('bookingConfirmed', true);
      set({
        booking: data,
      });

      return data;
    },
    openOrCloseModal: (opened) => set({ opened }),
    setSaidas: ({
      saidaEmergPrimC,
      saidaEmergPremium,
      saidaEmergExecutiva,
      saidaEmergEconomica,
    }) => {
      if (saidaEmergPrimC) {
        set({ saidaEmergPrimC });
      }
      if (saidaEmergPremium) {
        set({ saidaEmergPremium });
      }
      if (saidaEmergExecutiva) {
        set({ saidaEmergExecutiva });
      }
      if (saidaEmergEconomica) {
        set({ saidaEmergEconomica });
      }
    },
    setReservationIds: (props) => {
      set({ reservationIds: props });
    },
    setDateValidate: (dateValidate) => set({ dateValidate }),

    setTotalQueue: (value) => set({ totalQueue: value }),
    setLoading: (loading) => {
      set({ loading });
    },
    addTravelers: (travelerInfo) => {
      set((prevState) => ({
        travelers: [...prevState.travelers, travelerInfo],
      }));
    },
    updateTravelerById: (id, newValues) => {
      set((prevState) => ({
        travelers: prevState.travelers.map((traveler) =>
          traveler.id === id ? { ...traveler, ...newValues } : traveler,
        ),
      }));
    },
    removeTravelers: (id) => {
      set((prevState) => ({
        travelers: prevState.travelers.filter((traveler) => traveler.id !== id),
      }));
    },
    updateModal: (name, status) => {
      set((prevState) => ({ modal: { ...prevState.modal, [name]: status } }));
    },
    setSeat: (seat) => {
      set((prevState) => ({ ...prevState, seat }));
    },
    updateTablePrice: (value) => {
      set({ tablePrice: value });
    },

    setTraveler: (traveler) => {
      set({ traveler });
    },
    updateStep: (step) => set({ step }),

    reset: () => {
      set({
        booking: null,
        travelers: [],
        traveler: null,
        modal: {
          createTravelerVisible: false,
          bookingConfirmed: false,
        },
        loading: false,
        flightDetail: null,
        seat: null,
        tablePrice: null,
        //!TODO: remove in future
        opened: false,
        seatClass: '',
        specialSeat: false,
        dateValidate: false,
        hasEnoughAllotments: true,
        hasEnoughCredits: true,
        type: null,
        actualStatus: '',
        reservationInformations: {},
        routeInformations: {},
        totalQueue: 0,
        saidaEmergPrimC: [],
        saidaEmergPremium: [],
        saidaEmergExecutiva: [],
        saidaEmergEconomica: [],
        reservationIds: null,
        seatValue: null,
      });
    },
  },
}));
