import { forwardRef, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { CalendarSelector } from '@components/calendar-selector';
import { InputText } from '@components/input/InputText';

const CalendarStatus = {
  Invisible: 'invisible',
  Visible: 'visible',
};

export const InputDatePicker = forwardRef(function InputDatePicker(
  {
    value,
    intervalStart,
    intervalEnd,
    changeOnly,
    onClick,
    onChange,
    ...props
  },
  ref,
) {
  const calendarRef = useRef(null);
  const [calendarStatus, setCalendarStatus] = useState(
    CalendarStatus.Invisible,
  );
  const isCalendarVisible = calendarStatus === CalendarStatus.Visible;
  const calendarOpacityClass = isCalendarVisible
    ? 'visible opacity-100'
    : 'invisible opacity-0';
  const formattedDate =
    value instanceof Date ? format(value, 'dd/MM/yyyy') : '';

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isCalendarVisible &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target)
      ) {
        setCalendarStatus(CalendarStatus.Invisible);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarVisible]);

  function handleClick() {
    if (onClick) onClick(() => setCalendarStatus(CalendarStatus.Visible));
    else setCalendarStatus(CalendarStatus.Visible);
  }

  function handleCalendarReset() {
    onChange('');
  }

  function handleCalendarApply(value) {
    onChange(value);
    setCalendarStatus(CalendarStatus.Invisible);
  }

  return (
    <div className="relative w-full">
      <InputText
        {...props}
        value={formattedDate}
        onChange={() => {}} // Prevents the input from being controlled
        readOnly
        onClick={handleClick}
        ref={ref}
      />
      <CalendarSelector
        aria-hidden={!isCalendarVisible}
        className={`top-19 absolute left-0 z-10 w-[732px] ${calendarOpacityClass}`}
        interval={{ start: intervalStart ?? props.value, end: intervalEnd }}
        changeOnly={changeOnly}
        onReset={handleCalendarReset}
        onApply={handleCalendarApply}
        ref={calendarRef}
      />
    </div>
  );
});
