export const PaymentStatus = {
  Pendente: 9,
  Pago: 12,
};

export const PaymentMethod = {
  Faturamento: 1,
  'Cartão de Crédito': 2,
  Pix: 3,
};

export const PaymentCategory = {
  Reserva: 'booking',
  Alteração: 'alteration',
  Cancelamento: 'cancellation',
};
