import { differenceInDays } from 'date-fns';
import { Button } from '@components/button';
import { formatToBRTz } from './utils';

export const CalendarFooter = ({ interval, disabled, onReset, onApply }) => {
  function formatDate(date) {
    return formatToBRTz(date, `EEEE, d 'de' MMMM`);
  }

  function makeResume() {
    const totalDays = differenceInDays(interval.end, interval.start) || 0;
    const totalDaysText = `${totalDays} noites`;

    let intervalText = '';
    if (interval.start) intervalText += ` - ${formatDate(interval.start)}`;
    if (interval.end) intervalText += ` à ${formatDate(interval.end)}`;

    return (
      <>
        <span className="font-semibold">{totalDaysText}</span>
        <span>{intervalText}</span>
      </>
    );
  }

  return (
    <div className="px-5">
      <div className="flex justify-between border-0 border-t border-solid border-neutral-300 py-4">
        <div className="inline-flex items-center">
          <p className="m-0 text-small">{makeResume()}</p>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            variant="ghost"
            className="m-0 min-h-0 basis-20 border-none"
            label="Apagar"
            disabled={disabled}
            onClick={onReset}
          />
          <Button
            className="m-0 min-h-0 basis-20 px-4 py-2"
            label="Aplicar"
            disabled={disabled}
            onClick={onApply}
          />
        </div>
      </div>
    </div>
  );
};
