import { useEffect, useId, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';

export const Select = ({ options, onChange, value, label }) => {
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const id = useId();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative select-none">
      <div className="flex w-full flex-col gap-2">
        <label
          htmlFor={id}
          className="m-0 text-sm font-medium text-neutral-800"
        >
          {label}
        </label>
        <div
          ref={buttonRef}
          id={id}
          className="flex h-12 items-center justify-between rounded-md !border !border-solid !border-neutral-600 bg-transparent px-4 py-[14px]"
          type="button"
          onClick={toggleDropdown}
        >
          <span className="text-sm font-normal">{value ?? 'Selecione'}</span>
          <BiChevronDown size={22} />
        </div>
      </div>

      {isOpen && (
        <div
          ref={dropdownRef}
          id="dropdownStates"
          className="absolute z-20 flex max-h-36 w-full flex-col divide-y divide-gray-100 overflow-x-auto rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-labelledby={id}
        >
          {options ? (
            options?.map((item) => (
              <div
                key={item.value}
                onClick={() => {
                  onChange(item.value);
                  setIsOpen(!isOpen);
                }}
                className="hover:gray-800 flex cursor-pointer items-center justify-between px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                role="menuitem"
              >
                {item.value}
              </div>
            ))
          ) : (
            <span>Empty</span>
          )}
        </div>
      )}
    </div>
  );
};
